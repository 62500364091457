import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { NODE_TYPE } from "../../shared/utils/constants";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./linkNode.module.scss";
import CustomButton from '../button'
import EditIcon from "@mui/icons-material/Edit";
import { v4 as uuidv4 } from "uuid";
import { SelectNode } from "../../shared";
import { toast } from "react-toastify";
import { CustomSummary, CloseIcon } from "../../shared";
import { CustomEditor } from "../../shared";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { ContentState } from "react-draft-wysiwyg";
import { ReactSortable } from "react-sortablejs";
// import { v4 as uuidv4 } from "uuid";

const LinkNode = ({
  tobeEdited,
  setTobeEdited,
  handleEdit,
  handleClose,
  elements,
  setElements,
  handleAddModal,
  enableMultiCondition,
  setUpdateIndicator,
}) => {
  const [text, setText] = useState("");
  const [text_es, setTextES] = useState("");
  const [childNode, setChildNode] = useState("");
  const [editedItem, setEditedItem] = useState("");
  const [type, setType] = useState("multi-link");
  const [edit, setEdit] = useState(false);
  const [optionIndex, setOptionIndex] = useState("");
  const [searchOption, setSearchOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [summaryCheck, setSummaryCheck] = useState(false);
  const [catagory, setCatagory] = useState("");
  const [summaryDesc, setSummaryDesc] = useState("");
  const [optionsChild, setOptionsChild] = useState(tobeEdited ? tobeEdited.data.child : [])
  // ?.replace(/&lt;/ig,'<')
  console.log(tobeEdited);
  // var contentState = stateFromHTML("");
  // console.log('summary Desc',summaryDesc,'resp',contentState);
  // contentState = EditorState.createWithContent(contentState);
  const [spanisCheck, setSpanishCheck] = useState(false);
  const [editorState, setContentState] = useState(EditorState.createEmpty());
  const [editorSpanishState, setSpanishContentState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editor) => {
    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setContentState(editor);
  };
  const onEditorSpanishStateChange = (editor) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setSpanishContentState(editor);
  };

  useEffect(() => {
    // draftToHtml(convertToRaw(EditorState.createWithContent(summaryDesc)))
    // console.log(tobeEdited?.data?.type);
    // setType(tobeEdited?.data?.type);
    setOptionsChild(tobeEdited.data.child)
  }, [tobeEdited,tobeEdited.data.child?.length]);
  const onDragChild = (childArr)=>{
    let cloneTobeEdited  = {...tobeEdited} 
    cloneTobeEdited.data.child = childArr
    setTobeEdited(cloneTobeEdited)

  }
  const handleSearchOptions = (searchData) => {
    let temp = elements.filter((item, index) => {
      let findInChildren = tobeEdited.data.child.findIndex(
        (newItem, newIndex) => newItem.targetNode === item.id
      );
      let findInParent = tobeEdited.data.parents.findIndex(
        (newItem, newIndex) => newItem
      );
      if (item.id === tobeEdited.id || findInChildren > -1) {
        return false;
      } else {
        if (
          item?.data?.label?.toLowerCase().includes(searchData.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      }
    });
    setSearchOption(temp);
  };
  const handleChange = (event) => {
    setChildNode(event.target.value);
  };

  const handleRemoveOption = (item) => {
    setUpdateIndicator(true);
    let tempElements = [...elements];
    let tempIndex = tempElements.findIndex(
      (newItem, index) =>
        newItem.target === item.targetNode && newItem.source === tobeEdited.id
    );
    tempElements.splice(tempIndex, 1);
    let index = tempElements.findIndex(
      (newItem) => newItem.id === tobeEdited.id
    );
    let tempOptions = [...tobeEdited.data.child];
    tempOptions = tempOptions.filter(
      (newItem, index) => newItem.id !== item.id
    );
    // find whether there is multiLink option
    let multiLinkFlag = false;
    tempOptions.forEach((item, index) => {
      if (item.targetNode === null) {
        multiLinkFlag = true;
      }
    });
    //
    tempElements[index].data.child = tempOptions;
    let tempConditions = [...tobeEdited.data.conditions];
    tempConditions = tempConditions.filter(
      (newItem, index) => !newItem?.condition?.includes(item.id)
    );
    tempElements[index].data.conditions = tempConditions;
    tempElements[index].data.type = multiLinkFlag
      ? NODE_TYPE.multiLinkFlag
      : NODE_TYPE.link;
    setElements(tempElements);
  };

  const onSubmit = () => {
    setUpdateIndicator(true);
    let checkNode = false;
    let multiLinkFlag = type === NODE_TYPE.multiLink ? true : false;
    tobeEdited.data.child.map((item, index) => {
      // if (item.targetNode === null) {
      //   multiLinkFlag = type;
      // }
    });
    let nodeType = multiLinkFlag ? NODE_TYPE.multiLink : NODE_TYPE.link;
    if (
      selectedOption?.data?.label === childNode ||
      type === NODE_TYPE.multiLink
    ) {
      console.log(editorSpanishState);
      checkNode = true;
      handleEdit(
        tobeEdited.id,
        selectedOption.id,
        text,
        text_es,
        nodeType,
        catagory,
        draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        )
        ,
        draftToHtml(
          convertToRaw(editorSpanishState.getCurrentContent())
        )

      );
    } else {
      checkNode = false;
      toast.error("Please Select Node to Link This Option", {
        theme: "colored",
        hideProgressBar: true,
      });
      // let id = uuidv4();
      // let temp = [...elements];
      // let index = elements.findIndex(
      //   (item, index) => item.id === tobeEdited.id
      // );
      // temp[index].data.child.push({
      //   id: uuidv4(),
      //   text: text,
      //   targetNode: id,
      //   summaryCatagory: catagory,
      //   summaryDescription: summaryDesc,
      // });
      // temp.push(
      //   {
      //     id: id,
      //     data: {
      //       label: childNode,
      //       description: "",
      //       parents: [tobeEdited.id],
      //       child: [],
      //       conditions: [],
      //       summary: "",
      //       summaryDescription: "",
      //     },

      //     position: { x: 100, y: 100 },
      //     style: {
      //       background: "#C3CCF4",
      //       border: "none",
      //     },
      //     type: "special",
      //   },
      //   {
      //     id: id,
      //     source: tobeEdited.id,
      //     target: id,
      //     animated: false,
      //     sourceHandle: "a",
      //     targetHandle: null,
      //     arrowHeadType: "arrowclosed",
      //   }
      // );

      // setElements(temp);
    }
    if (checkNode) {
      setText("");
      setChildNode("");
      setCatagory("");
      setSummaryDesc("");
      setTextES("")
      setContentState(EditorState.createEmpty());
      setSpanishContentState(EditorState.createEmpty())
    }
  };

  const editParent = () => {
    setUpdateIndicator(true);
    let temp = [...elements];

    let itemIndex = elements.findIndex(
      (newItem, index) => newItem.id === tobeEdited.id
    );
    console.log("elements", temp[itemIndex], editedItem);
    let nodelinkIndex = -1;
    let editLinkingNode = {};
    if (editedItem.targetNode != null) {
      nodelinkIndex = temp.findIndex((ii) => {
        if (editedItem.targetNode == ii.target) {
          return ii;
        }
      });
      let nodeLinkItem = temp[nodelinkIndex];
      console.log(nodeLinkItem);
    }

    // if(type == NODE_TYPE.multiLink |)
    // -------------------- update target node  id
    if (nodelinkIndex > -1) {
      if (type == NODE_TYPE.multiLink) {

        // do noting in this seciont
        editLinkingNode = {
          ...temp[nodelinkIndex],
          target: "",
          source: temp[itemIndex].id,
        };
      } else {
        editLinkingNode = {
          ...temp[nodelinkIndex],
          target: selectedOption && selectedOption.id,
          source: temp[itemIndex].id,
        };
      }

      temp[nodelinkIndex] = editLinkingNode;
    } else {
      if (!childNode && type == NODE_TYPE.link) {
        toast.error("Please Select Node to Link This Option", {
          theme: "colored",
          hideProgressBar: true,
        });
        return false;
      } else if (childNode && type == NODE_TYPE.link) {
        editLinkingNode = {
          id: uuidv4(),
          source: temp[itemIndex].id,
          target: selectedOption.id,
          animated: false,
          sourceHandle: "a",
          targetHandle: null,
          arrowHeadType: "arrowclosed",
        };
        temp.push(editLinkingNode);
      }
    }
    // let editLinkingNode = {
    //   ...temp[nodelinkIndex],
    //   target:selectedOption.id,
    //   source:temp[itemIndex].id
    // }
    console.log("change linke nodes", editLinkingNode);

    let Obj = { ...temp[itemIndex] };
    let cloneChildItem = {
      ...editedItem,
      targetNode: editLinkingNode.target ? editLinkingNode.target : null,
      summaryCatagory: catagory,
      summaryDescription: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
      summaryDescription_ES: draftToHtml(
        convertToRaw(editorSpanishState.getCurrentContent())
      ),
      text: text,
      text_ES: text_es,
    };
    let findItemIndex = Obj.data.child.findIndex(
      (iii) => iii.id == editedItem.id
    );

    //  change
    Obj.data.child[findItemIndex] = cloneChildItem;
    console.log("final", temp[itemIndex]);
    console.log("elements", elements[itemIndex]);

    // let checkNode = temp.filter((item)=>{

    //   if(item?.source == temp.id || item?.target == temp.id){
    //      return item
    //   }
    //  })
    //  console.log('check nodes',checkNode);
    // temp = temp.filter((newItem, index) => {
    //   if (
    //     newItem.target === editedItem.targetNode &&
    //     newItem.source === tobeEdited.id
    //   ) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // });

    console.log("temp", temp[itemIndex]);
    // temp[itemIndex].data.child[optionIndex].targetNode = selectedOption.id;
    // temp[itemIndex].data.child[optionIndex].text = text;
    // if (temp[itemIndex].data.child[optionIndex].summaryCatagory) {
    //   temp[itemIndex].data.child[optionIndex]["summaryCatagory"] = catagory;
    //   temp[itemIndex].data.child[optionIndex]["summaryDescription"] =
    //     summaryDesc;
    // } else {
    //   temp[itemIndex].data.child[optionIndex].summaryCatagory = catagory;
    //   temp[itemIndex].data.child[optionIndex].summaryDescription = summaryDesc;
    // }
    // temp.push({
    //   id: uuidv4(),
    //   source: tobeEdited.id,
    //   target: selectedOption.id,
    //   animated: false,
    //   sourceHandle: "a",
    //   targetHandle: null,
    // });
    setElements(temp);
    setEdit(!edit);
    setText("");
    setTextES("")
    setChildNode("");
    setCatagory("");
    setSummaryDesc("");
    setContentState(EditorState.createEmpty());
    setSpanishContentState(EditorState.createEmpty());

    toast.success("You have edited the item successfully!", {
      theme: "colored",
      hideProgressBar: true,
    });
  };
  const handleEditOption = (item, index) => {
    console.log('item', item);
    setType(item.targetNode ? "link" : "multi-link")
    setText(item.text);
    setTextES(item.text_ES || "")
    setCatagory(item.summaryCatagory || "");
    // setSummaryDesc(item.summaryDescription || "");
    console.log(tobeEdited);
    var contentState = stateFromHTML(item.summaryDescription?.replace(/&lt;/ig, '<') || "");
    var contentSpanishState = stateFromHTML(item.summaryDescription_ES?.replace(/&lt;/ig, '<') || "");
    console.log("summary Desc", summaryDesc, "resp", contentState);
    contentState = EditorState.createWithContent(contentState);
    contentSpanishState = EditorState.createWithContent(contentSpanishState);
    setContentState(contentState);
    setSpanishContentState(contentSpanishState)
    let temp = elements.filter(
      (newItem, newIndex) => newItem.id === item.targetNode
    );
    setSelectedOption(temp[0]);
    if (temp[0]?.data?.label) {
      setChildNode(temp[0]?.data?.label);
    } else {
      setChildNode("");
    }
    setSpanishCheck(true)
    setEditedItem(item);
    setOptionIndex(index);
    setEdit(true);
  };

  const handleSelect = (item) => {
    setChildNode(item.data.label);
    setSelectedOption(item);
    setSearchOption([]);
  };
  return (
    <div className={styles.container}>
      <DialogContent>
        {/* <CloseIcon handleClose={handleClose} /> */}
        <div className={`${styles.defaultMarginContainer} ${styles.marginLeft}`}>

          <CustomButton label={"Edit Spanish Language"} active={spanisCheck} handle={() => setSpanishCheck(!spanisCheck)} />
        </div>
        {/* <FormControlLabel
            control={
              <Checkbox
                checked={spanisCheck}
                onClick={() => setSpanishCheck(!spanisCheck)}
              />
            }
            label="Spanish"
          /> */}
        <div className={styles.optionContainer}>
          <ReactSortable list={optionsChild} setList={(e=>onDragChild(e))}>
            {optionsChild?.map((item, index) => {
              return (
                <div key={index} className={styles.optionInnerContainer} >
                  <div className={styles.optionInnerStyles}>
                    <div className={styles.optionTextStyles}>
                      <p>{item.text}</p>
                    </div>
                    <div className={styles.iconSubContainer}>
                      <div
                        className={styles.editIconContainer}
                        onClick={() => handleEditOption(item, index)}
                      >
                        <EditIcon />
                      </div>
                      <div
                        className={styles.deleteIconContainer}
                        onClick={() => handleRemoveOption(item)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </ReactSortable>
        </div>
        {/* <div className={styles.addNewNodeContainer}>
          <Button
            variant="contained"
            className={styles.nextStyles}
            onClick={handleAddModal}
          >
            Add New Node
          </Button>
        </div> */}
        <div className={`${styles.dflex} ${styles.defaultMarginContainer} ${styles.marginLeft} ${styles.marginTop20} `}>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={type === "link" ? false : true}
                onClick={() => {
                  setType(NODE_TYPE.multiLink);
                }}
              />
            }
            label="Multi Link"
          /> */}
          <div className={styles.marginRight}>

            <CustomButton label={"Multiple Answer Node"} active={type === "link" ? false : true} handle={() => setType(NODE_TYPE.multiLink)} />
          </div>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={type === "link" ? true : false}
                onClick={() => setType(NODE_TYPE.link)}
              />
            }
            label="Link"
          /> */}
          <CustomButton label={"Single Answer Node"} active={type === "link" ? true : false} handle={() => setType(NODE_TYPE.link)} />

        </div>
        <div className={`${styles.defaultMarginContainer} ${styles.marginLeft}`}>
          <InputLabel id="label1" className={styles.labelStyles}>
            Option:
          </InputLabel>
          <TextField
            value={text}
            margin="dense"
            id="label1"
            label="Add Option"
            type="text"
            fullWidth
            variant="outlined"
            className={styles.minWidth}
            onChange={(e, value) => setText(e.target.value)}
          />
          {spanisCheck && (
            <>
              {" "}
              <InputLabel id="label1" className={styles.labelStyles}>
                Opción:
              </InputLabel>
              <TextField
                value={text_es}
                margin="dense"
                id="label1"
                label="Añadir opción"
                type="text"
                fullWidth
                variant="outlined"
                className={styles.minWidth}
                onChange={(e, value) => setTextES(e.target.value)}
              />
            </>
          )}
        </div>
        <div className={`${styles.defaultMarginContainer} ${styles.marginLeft}`}>
          {console.log("multi links", type)}
          {type != "multi-link" ? (
            <>
              <InputLabel id="label2" className={styles.labelStyles}>
                Enter Child node
              </InputLabel>
              {console.log("edit", childNode)}
              {edit ? (
                <SelectNode
                  childNode={childNode}
                  setChildNode={setChildNode}
                  tobeEdited={tobeEdited}
                  setSelectedOption={setSelectedOption}
                  handleSelect={handleSelect}
                />
              ) : (
                <SelectNode
                  childNode={childNode}
                  setChildNode={setChildNode}
                  tobeEdited={tobeEdited}
                  setSelectedOption={setSelectedOption}
                  handleSelect={handleSelect}
                />
              )}
            </>
          ) : null}
        </div>
        <div className={`${styles.defaultMarginContainer} ${styles.marginLeft} ${styles.marginTop20}`}>
          <CustomButton label={"Add text to View Your Evaluation"} active={summaryCheck} handle={() => {
            setSummaryCheck(!summaryCheck);
          }} />
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={summaryCheck}
                onClick={() => {
                  setSummaryCheck(!summaryCheck);
                }}
              />
            }
            label="Add text to View Your Evaluation"
          /> */}
        </div>
        <div className={`${styles.defaultMarginContainer} ${styles.marginLeft} ${styles.marginTop20}`}>

          {summaryCheck ? (
            <>
              <CustomSummary
                catagory={catagory}
                setCatagory={setCatagory}
                summaryDesc={editorState}
                categoryBool={true}
                onEditorStateChange={onEditorStateChange}
                placeholderText={"Enter Summary Description"}
                title={"Enter Summary Description:"}
              />
              {spanisCheck && (
                <div style={{ marginTop: '10px' }}>
                  <CustomSummary
                    catagory={catagory}
                    setCatagory={setCatagory}
                    summaryDesc={editorSpanishState}
                    categoryBool={false}
                    onEditorStateChange={onEditorSpanishStateChange}
                    placeholderText={"Ingrese la descripción resumida"}
                    title={"Ingrese la descripción resumida:"}
                  />
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className={styles.addParentContainer}>
          {edit ? (
            <Button
              variant="contained"
              onClick={editParent}
              className={styles.nextStyles}
            >
              Edit Parent
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={onSubmit}
              className={styles.nextStyles}
            >
              Add Parent
            </Button>
          )}
        </div>
      </DialogContent>
    </div>
  );
};
export default LinkNode;
