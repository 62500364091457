import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import styles from "./addNode.module.scss";
import ClearIcon from "@mui/icons-material/Clear";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { CustomEditor } from "../../shared";
import { toast } from "react-toastify";
import ActionLink from "../actionLink/actionLink"
import CustomButton from '../button'
const AddNode = ({ modal, handleClose, handleAdd, setUpdateIndicator }) => {
  const [nodeLabel, setLabel] = useState("");
  const [SpaLabel, setSpaLabel] = useState("")
  const [nodeDescription, setDescription] = useState("");
  const [spanisCheck, setSpanishCheck] = useState(false);
  const [isConditional, setConditional] = useState(false);
  const [scheduleBool, setScheduleBool] = useState(false)
  const [immigrationBool, setimmigrationBool] = useState(false)
  const [editorState, setContentState] = useState("");
  const [linkArr, setLinkArr] = useState([])
  const [modalres, setModal] = useState(false);
  const [SpnsheditorState, setSpnshContentState] = useState("");

  const onEditorStateChange = (editor) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setContentState(editor);
  };
  const onEditorSpanishStateChange = (editor) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setSpnshContentState(editor);
  };

  const onSubmit = () => {
    if (editorState && nodeLabel) {
      let tempDescription = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );

      let tempEsDescription = SpnsheditorState ? draftToHtml(
        convertToRaw(SpnsheditorState ? SpnsheditorState.getCurrentContent() : "")
      ) : ""
      handleAdd(nodeLabel, SpaLabel, tempDescription, tempEsDescription, isConditional, linkArr);
      setLabel("");
      setDescription("");
      setContentState("")
      setConditional(false);
      setUpdateIndicator(true)
    } else {
      toast.error("Please Provide Node Label and Descriptions!!", { theme: "colored", hideProgressBar: true });
    }

  };
  const handleAddModal = () => {
    setModal(!modalres)
  }
  return (
    <div>
      <Dialog open={modal} onClose={handleClose} fullWidth maxWidth="md">
        <div className={styles.container}>
          <DialogTitle className={styles.titleStyles}>
            <ClearIcon className={styles.iconStyles} onClick={handleClose} />
          </DialogTitle>
          <DialogContent>
            <div className={styles.defaultMarginStyles}>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={isConditional}
                    onClick={() => {
                      setConditional(!isConditional);
                    }}
                  />
                }
                label="Select if you want to make this node conditional"
              /> */}
              <CustomButton handle={()=>{ setConditional(!isConditional);}} label={"Select if you want to make this node conditional"} active={isConditional} />
            </div>

            <div className={styles.defaultMarginStyles}>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={spanisCheck}
                    onClick={() => {
                      setSpanishCheck(!spanisCheck);
                    }}
                  />
                }
                label="Select if you want to add the node content in Spanish"
              /> */}
                <CustomButton handle={()=>{ setSpanishCheck(!spanisCheck);}} label={"Select if you want to add the node content in Spanish"} active={spanisCheck} />
            </div>
            <div className={styles.defaultMarginStyles}>
              <InputLabel id="label1" className={styles.labelStyles}>
                Node Text:
              </InputLabel>
              <TextField
                value={nodeLabel}
                margin="dense"
                id="label1"
                label="Name"
                type="text"
                fullWidth
                variant="outlined"
                className={styles.minWidth}
                onChange={(e, value) => setLabel(e.target.value)}
              />
              {
                spanisCheck && <><InputLabel id="label1" className={styles.labelStyles}>
                  Etiqueta:
                </InputLabel>
                  <TextField
                    value={SpaLabel}
                    margin="dense"
                    id="label1"
                    label="Agregar nodo"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className={styles.minWidth}
                    onChange={(e, value) => setSpaLabel(e.target.value)}
                  /></>
              }

            </div>
            {/* <InputLabel id="description1" className={styles.labelStyles}>
              Description:
            </InputLabel>
            <TextField
              value={nodeDescription}
              margin="dense"
              id="description1"
              label="Add Description"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e, value) => setDescription(e.target.value)}
            /> */}
            <div className={styles.defaultMarginStyles}>
              <CustomEditor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
              />
            </div>
            {
              spanisCheck && <div className={styles.defaultMarginStyles}>
                <CustomEditor
                  editorState={SpnsheditorState}
                  onEditorStateChange={onEditorSpanishStateChange}
                  placeholderText={"Por favor ingrese la descripción"}
                />
              </div>
            }

          </DialogContent>
          <DialogActions>
            <div className={styles.nextContainer}>
              <Button
                variant="contained"
                className={`${styles.nextStyles} ${styles.mr}`}
                onClick={handleAddModal}
              >
                Action Link
              </Button>
              <Button
                variant="contained"
                onClick={onSubmit}
                className={styles.nextStyles}
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
      <ActionLink
        modal={modalres}
        linkArr={linkArr}
        setLinkArr={setLinkArr}
        handleClose={handleAddModal}
      />
    </div>
  );
};
export default AddNode;
