import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Modifier, EditorState } from "draft-js";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from "./customEditor.module.scss";
import { allEmoji } from './emoji'
import Placeholders from './test'
const CustomEditor = ({ editorState, onEditorStateChange, placeholderText }) => {
  return (
    <div className={styles.container}>

      <Editor
        editorState={editorState}
        name="dynamicContent"
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={(editor) => onEditorStateChange(editor)}
        wrapperStyle={{
          backgroundColor: "#fffaed",
        }}
        toolbar={{
          emoji: allEmoji,
        }}
        // toolbarCustomButtons={[<Placeholders />]} // i'm  working but not possible to add ids
        toolbarStyle={{ backgroundColor: "#fffaed" }}
        placeholder={placeholderText ? placeholderText : "Please enter description"}
      // toolbarClassName="rdw-option-wrapper"
      />
    </div>
  );
};
export { CustomEditor };
