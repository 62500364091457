import axios from "axios";
import { REACT_APP_Laravel_BASE_URL } from "./constants";
export const HTTP_Request = axios.create({
  baseURL: REACT_APP_Laravel_BASE_URL,
});
HTTP_Request.interceptors.request.use(
  (config) => {
    
    let loginDetail = JSON.parse(localStorage.getItem("userToken"))
    if(loginDetail){
       
      // config.headers.Authorization =  `Bearer ${loginDetail.token}`;
      config.headers.Authorization =  `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNzAyNWQzMTkyMzdiMzNiOTU1MzcwZDRhMjNmZDQ3MTJmYTgwMjc4MWM1YTgwYzg2MDMxNTZiNDM4MThkMTExN2QyMDkzY2JkZTYyNmUxNDEiLCJpYXQiOjE2NjcxOTY4ODIuMDQzODI1LCJuYmYiOjE2NjcxOTY4ODIuMDQzODI3LCJleHAiOjE2OTg3MzI4ODIuMDM5MzM5LCJzdWIiOiIxMCIsInNjb3BlcyI6W119.TaHB1xBgN71GxMJCaSUKTHjPRIErqT_b1PltVUju3evnDEEZZS6vNjXQ84-7rz-wOQOa9s-UCx5M8D4BEVVXZUUVuHz9R-UZigz2bNfaN696L92c33hVl3x-qSoC9YVAYULXTiYXr4E_rx2oE-agTItNsJJ2H26cR5a_dafFhJjC2QNdstu1zbzGgiIgW47f2pxzbP3lrMvYUFM7pPKEaL3yvG9CEfMHw4DvYgO9R8Ydp55srb18FTRnQ1BD1JC3MuDySb359yKArRReFTHA1ertZqKPs4c-hp1uSnUz0B1jdrTURfUGk6_WqOnFpVJ8uJAsrS7bwtMH60uumxn5sJ9ln89CExey7JBWMby-OewDw99K58UgkmnXtmVldhS5m5mismMlxJ6om1TZaeqIcM2kW642i3EmAaZ0w-cQ335i5uJG3MY1pQAOL06e3A-wqYpDvzJ8vsWTxh0JxIBgcPrOm465TTAB7wvERrZck8O-Rsfs73XTISDJ4RhG2rlL6FGJC7fA3pKcG9dTxkRqepbirEKiaxokhbCEvuj7BYW2Zk-IHACQof7oZzQCeaoyNNzrxHXH44ieGNz8lYhtpKfP_YkfIS2OAQFSohQgH1fDAmHOZJIrIlwsIday4cf0NJlARDsRVwmqcbSVDKbCrNrE0KO5-8H0FtQ0m8dIRJs`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);


