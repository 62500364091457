const computeCondition = (conditions) => {
  var final_Condition = "";
  debugger
  conditions?.forEach((item, index) => {
    
    if (index + 1 === conditions.length) {
      if (item.optionId !== "") {
        final_Condition = final_Condition + `${item.nodeId}&&${item.optionId}`;
      } else {
        final_Condition = final_Condition + `${item.nodeId}`;
      }
      if (item?.extraNodes?.length > 0) {
        item.extraNodes.forEach((newItem, newIndex) => {
          if (newItem.optionId !== "") {
            final_Condition =
              final_Condition + `||${newItem.nodeId}&&${newItem.optionId}`;
          } else {
            final_Condition = final_Condition + `||${newItem.nodeId}`;
          }
        });
      }
    } else {
      if (item.optionId !== "" ) {
        final_Condition = final_Condition + `${item.nodeId}&&${item.optionId}`;
      } else {
        final_Condition = final_Condition + `${item.nodeId}`;
      }
  
      // or conditions
      if (item?.extraNodes?.length > 0) {
        item.extraNodes.forEach((newItem, newIndex) => {
          if (newItem.optionId !== "" && newItem.nodeLabel) {
            final_Condition =
              final_Condition + `||${newItem.nodeId}&&${newItem.optionId}`;
          }else{
            final_Condition = final_Condition + `||${newItem.nodeId}`;
          }
          
        });
      }
      final_Condition = final_Condition + `>${item.andOr}>`;
    }
  });
  console.log('final condition',final_Condition);
  return final_Condition;
};
export { computeCondition };
