import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useAppContext } from "../contextApi/appContext";
import styles from "./selectNode.module.scss";
const SelectNode = ({ childNode, setChildNode, tobeEdited, handleSelect }) => {
  const { elements } = useAppContext();
  const [searchOption, setSearchOption] = useState([]);

  const handleSearchOptions = (searchData) => {
    let temp = elements.filter((item, index) => {
      let findInChildren = tobeEdited.data.child.findIndex(
        (newItem, newIndex) => newItem.targetNode === item.id
      );
      let findInParent = tobeEdited.data.parents.findIndex(
        (newItem, newIndex) => newItem
      );
      if (item.id === tobeEdited.id) {
        return false;
      } else {
        if (
          item?.data?.label?.toLowerCase().includes(searchData.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      }
    });
    setSearchOption(temp);
  };
  return (
    <div className={styles.container}>
      <TextField
        value={childNode}
        margin="dense"
        id="label1"
        label="Add Option"
        type="text"
        fullWidth
        variant="outlined"
        autoComplete="off"
        className={styles.minWidth}
        onChange={(e, value) => {
          setChildNode(e.target.value);
          handleSearchOptions(e.target.value);
        }}
      />
      {searchOption.length > 0 && (
        <div className={styles.searchOptionContainer}>
          {searchOption.map((item, index) => {
            return (
              <div
                key={index}
                //
                className={`${styles.searchOption} ${styles.searchOptionsPadding}`}
                onClick={() => {
                  handleSelect(item);
                  setSearchOption([]);
                }}
              >
                {item.data.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export { SelectNode };
