import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { TextField } from "@mui/material";
import styles from "./customSummary.module.scss";
import { CustomEditor } from "..";
const CustomSummary = ({
  catagory,
  setCatagory,
  categoryBool,
  summaryDesc,
  onEditorStateChange,
  placeholderText,
  title
}) => {
  return (
    <div>
      <div className={styles.defaultMarginContainer}>
        {
          categoryBool && <FormControl fullWidth className={styles.conditionMargin}>
          <InputLabel id="catagory-node-select">Select Catagory</InputLabel>
          <Select
            labelId="select-target-node-label"
            id="target-node-select"
            value={catagory}
            label="Select Target Node"
            onChange={(e) => setCatagory(e.target.value)}
          >
            <MenuItem value="">Select Category</MenuItem>
            <MenuItem value="immigrationHistory">Immigration history</MenuItem>
            <MenuItem value="factorsOptions">Factors options</MenuItem>
            <MenuItem value="inadmissibility">
              Grounds of inadmissibility
            </MenuItem>
          </Select>
        </FormControl>
        }
        
      </div>
      <div className={styles.defaultMarginContainer}>
        <InputLabel id="label1" className={styles.labelStyles}>
          {title}
        </InputLabel>
      <CustomEditor
                    editorState={summaryDesc}
                    placeholderText={placeholderText}
                    onEditorStateChange={onEditorStateChange}
                  />
        {/* <TextField
          value={summaryDesc}
          margin="dense"
          id="label1"
          label="Enter Summary Description"
          type="text"
          fullWidth
          multiline={true}
          variant="outlined"
          className={styles.minWidth}
          onChange={(e) => setSummaryDesc(e.target.value)}
        /> */}
      </div>
    </div>
  );
};
export { CustomSummary };
