import React from 'react'
import {Route,Routes} from 'react-router-dom';
import {privateRoute,publicRoute} from './allRoutes'
import Layout from './layout';
import { useAppContext } from "../../shared";
function AuthRoute() {
    const {loginUser} = useAppContext();
    return (
        <Routes>
        {
            loginUser ? privateRoute.map((rte)=>{
                return <Route path={rte.path} key={rte.path} element={<Layout {...rte} />} />
            }):publicRoute.map((rte)=>{
                return <Route path={rte.path} key={rte.path} element={<Layout {...rte} />} />
            })
        }
        
        </Routes>
    )
}

export default AuthRoute
