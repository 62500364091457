import React, { useState, useCallback, useRef } from "react";
import ReactFlow, {
  removeElements,
  addEdge,
  MiniMap,
  updateEdge,
  Controls,
  Background,
  Handle,
} from "react-flow-renderer";
import { toast } from "react-toastify";

import AddNode from "../../components/addNode/addNode";
import EditNode from "../editNode/editNode";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "./overViewFlow.module.scss";
import { NODE_TYPE } from "../../shared/utils/constants";
import { useAppContext, ConfirmationDialog } from "../../shared";
import {
  Play,
  Download,
  Reload,
  Save,
  Subtraction,
  Logout,
} from "../../assets";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { saveQuestionService } from "../../shared";
import Loader from "../../shared/loader/loader";
import Search from "./Sarch";
import ViewNodes from "../viewNodes";
import { stateFromHTML } from "draft-js-import-html";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { optionUnstyledClasses } from "@mui/base";
import {allConditions } from './txt'
const OverviewFlow = () => {
  const {
    elements,
    setElements,
    tobeEdited,
    setTobeEdited,
    loader,
    setLoader,
    setUpdateIndicator,
    updateInd,
    setLoginUser,
    conditionList,
  } = useAppContext();
  const [nodeSummary, setNodeSummary] = useState([]);
  const [rfInstance, setRfInstance] = useState(null);
  const [showEdit, setEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [viewNodeModal, setViewNodeModal] = useState(false);
  const [dataItem, setItem] = useState("");
  const [open, setOpen] = useState(false);
  const [nodes, setNodes] = useState([]);

  // const [loader,setLoader] = useState(false)
  useEffect(() => {
    // localStorage.setItem("tempElements", JSON.stringify(elements));
    // console.log(elements);
  }, [elements?.length]);

  const onSave = useCallback(() => {
    setLoader(true);
    if (rfInstance) {
      const flow = rfInstance.toObject();
      
      let cloneElement = [...flow.elements];
      cloneElement[0]["isJson"] = true;
      
      let obj = {
        data: cloneElement,
        conditionArr: allConditions,
        hasUpdated: updateInd,
      };
      saveQuestionService(obj)
        .then(({ data }) => {
          setElements(data.data.questions);
          setLoader(false);
          setUpdateIndicator(false);

          toast.success("Your data saved successfully", {
            theme: "colored",
            hideProgressBar: true,
          });
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
      localStorage.setItem("elements", JSON.stringify(flow.elements));
    }
  }, [rfInstance, conditionList]);

  const onEdgeUpdate = (oldEdge, newConnection) =>
    setElements((els) => updateEdge(oldEdge, newConnection, els));

  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const onConnect = (params) => {
    // params["animated"] = true;
    // setElements((els) => addEdge(params, els));
  };

  const handleAddModal = () => {
    setModal(!modal);
  };

  const handleAdd = (
    nodeLabel,
    nodeLabel_es,
    nodeDescription,
    nodeDescription_es,
    isConditional,
    actionLink
  ) => {
    var newTemp = "";
    elements.forEach((item, index) => {
      if (item?.position) {
        newTemp = item.position;
      }
    });
    let temp = [...elements];
    temp.push({
      id: uuidv4(),
      data: {
        label: nodeLabel,
        label_ES: nodeLabel_es,
        description: nodeDescription,
        description_ES: nodeDescription_es,
        parents: [],
        child: [],
        conditions: [],
        summary: "",
        summaryDescription: "",
        isConditional: isConditional,
        actionLink: actionLink,
      },

      position: {
        x: newTemp.x ? newTemp.x + 250 : 250,
        y: newTemp.y ? newTemp.y : 250,
      },
      style: {
        background: "#C3CCF4",
        border: "none",
      },
      type: "special",
    });
    setElements(temp);
    setUpdateIndicator(true);
    setModal(false);
  };

  const onNodeClick = (event, node) => {
    let cloneTemp = [...elements];
    let allOptions = [];
    let allEle = cloneTemp.filter((ii) => ii?.source == node.id);
    for (let index = 0; index < cloneTemp.length; index++) {
      const element = cloneTemp[index];
      if (element?.source == node.id) {
        if (!node?.data?.child?.length && !node?.data?.conditions?.length) {
          let findNodinx = cloneTemp.findIndex((ii) => ii?.source == node.id);
          cloneTemp.splice(findNodinx, 1);
        } else if (
          node?.data?.child?.length ||
          node?.data?.conditions?.length
        ) {
          // let allOptions = []
          allOptions = allOptions.concat(node?.data?.child);
          node?.data?.conditions?.forEach((contItem) => {
            allOptions = allOptions.concat(contItem?.conditionOptions);
          });
          // console.log(allOptions,'element',element);

          // for (let index = 0; index < allOptions.length; index++) {
          //   // const optionElement = allOptions[index];
          //   // let checkLink = cloneTemp.find(ii=> ii?.source == node.id && optionElement?.targetNode != ii?.target);
          //   // if(checkLink){
          //   //     let optionRemoveInd = cloneTemp.findIndex(ii=> (ii?.source == checkLink?.source && ii?.target == checkLink?.target));

          //   //   if(optionRemoveInd > -1){

          //   //       cloneTemp.splice(optionRemoveInd,1)
          //   //     }

          //   // }
          //   // if( (element?.source == node.id  && !(element?.target == optionElement?.targetNode))){

          //   // }
          //   // if((element?.source == node.id  && element?.target == optionElement?.targetNode)){

          //   // }else{
          //   //   let optionRemoveInd = cloneTemp.findIndex(ii=> (ii?.source == element.source && ii?.target == optionElement?.targetNode));
          //   //   if(optionRemoveInd > -1){

          //   //     cloneTemp.splice(optionRemoveInd,1)
          //   //   }
          //   //   console.log('ele',element);
          //   // }
          //   // if(!(element?.source == node.id  && element?.target == optionElement?.targetNode)){
          //   //   let optionRemoveInd = cloneTemp.findIndex(ii=> (ii?.source == node.id && ii?.target == optionElement?.targetNode));
          //   //   if(optionRemoveInd > -1){

          //   //     cloneTemp.splice(optionRemoveInd,1)
          //   //   }
          //   // }

          // }
        }
      }
    }
    let unqiueArr = allOptions.filter((v, i, a) => a.indexOf(v) === i);
    let filEle = cloneTemp.filter((fil) => fil?.source == node.id);
    let ele = [];
    filEle.filter((aa) => {
      unqiueArr.filter((bb) => {
        if (!unqiueArr.some((item) => item.targetNode === aa.target)) {
          ele.push(aa);
        }
      });
    });
    ele = [...new Set(ele)];
    if (ele.length) {
      for (let index = 0; index < ele.length; index++) {
        const elmt = ele[index];
        let clonTmp = cloneTemp.find((ii) => ii?.id == elmt.id);
        if (clonTmp) {
          let optionRemoveInd = cloneTemp.findIndex(
            (ii) => ii?.id == clonTmp?.id
          );

          if (optionRemoveInd > -1) {
            cloneTemp.splice(optionRemoveInd, 1);
          }
        }
      }
    }

    setElements(cloneTemp);
    setTobeEdited(node);
    setEdit(!showEdit);
  };
  const handleClose = () => {
    setModal(false);
  };
  //   handleEdit method edit the node
  const handleEdit = (
    id,
    childNodeId,
    text,
    textEs,
    type,
    catagory,
    summaryDesc,
    summaryDescES
  ) => {
    let index = elements.findIndex((obj) => obj.id === id);
    let temp = elements[index];
    temp.data.child.push({
      id: uuidv4(),
      targetNode: childNodeId ? childNodeId : null,
      text: text,
      text_ES: textEs,
      summaryCatagory: catagory,
      summaryDescription: summaryDesc,
      summaryDescription_ES: summaryDescES,
    });
    temp.data.type = type;
    if (type === NODE_TYPE.link) {
      let indexChild = elements.findIndex((obj) => obj.id === childNodeId);
      let childNode = elements[indexChild];

      // const parents = temp.data.parents;
      // let newParents = [...parents, ...childNode.data.parents, id];
      // newParents = [...new Set(newParents)];
      // childNode.data.parents = newParents;
      let newTemp = [...elements];
      newTemp[index] = temp;
      newTemp[indexChild] = childNode;
      newTemp.push({
        id: uuidv4(),
        source: id,
        target: childNodeId,
        animated: false,
        sourceHandle: "a",
        targetHandle: null,
        arrowHeadType: "arrowclosed",
      });
      setElements(newTemp);
    } else {
      let newTemp = [...elements];
      newTemp[index] = temp;
      setElements(newTemp);
    }
  };
  //   handleEditNode method open the edit popup
  const handleEditNode = (id) => {
    let temp = JSON.parse(localStorage.getItem("tempElements"));
    temp = temp.find((item, index) => item.id === id);
    setTobeEdited(temp);
    setEdit(!showEdit);
  };

  const handleDeleteNode = (data) => {
    let temp = elements.find((item, index) => item.data === data);
    let checkNode = elements.filter((item) => {
      if (item?.source == temp.id || item?.target == temp.id) {
        return item;
      }
    });
    if (checkNode.length > 0) {
      toast.error("Linked Nodes Can't be Deleted!!", {
        theme: "colored",
        hideProgressBar: true,
      });
    } else {
      let newTemp = elements.filter(
        (item, index) =>
          item.id !== temp.id &&
          item.target !== temp.id &&
          item.source !== temp.id
      );
      setElements(newTemp);
    }
  };
  const handleYes = () => {
    handleDeleteNode(dataItem);
    setOpen(!open);
  };
  const handleCloseToggle = () => {
    setOpen(!open);
  };
  const setDeleteItem = (data) => {
    setItem(data);
    setOpen(true);
  };
  const [test, setTest] = useState(EditorState.createEmpty());
  const openViewNodesModal = () => {
    let tempElements = [...elements];
    let arr = [];
    const regex = /(<([^>]+)>)/gi;

    for (let i = 0; i < tempElements.length; i++) {
      let check = false;
      let missing = {};

      if (tempElements[i]?.type != "default") {
        missing.label = tempElements[i].data.label;
        let contentState = stateFromHTML(tempElements[i]?.data?.description_ES);
        let plainText = contentState.getPlainText();
        let desc_ES = plainText.replace(regex, "");

        contentState = stateFromHTML(tempElements[i]?.data?.description);
        plainText = contentState.getPlainText();
        let desc = plainText.replace(regex, "");

        if (tempElements[i]?.data?.description == "" || desc == "") {
          check = true;
          missing.description = "Description is Missing";
        }

        if (tempElements[i]?.data?.description_ES == "" || desc_ES == "") {
          missing.description_ES = "Spanish Description is Missing.";
          check = true;
        }

        if (tempElements[i].data.label_ES == "") {
          missing.label_ES = "Spanish Label is Missing.";
          check = true;
        }

        let options = [];

        for (let k = 0; k < tempElements[i]?.data?.child?.length; k++) {
          let options_item = {};

          let child = tempElements[i]?.data?.child[k];

          if (child?.text == "") {
            options_item.text = `Text is Missing.`;
          }

          if (child?.text_ES == "") {
            options_item.text_ES = `Spanish Text is Missing.`;
            if (!options_item.text) {
              options_item.text = child?.text;
            }
          }

          if (Object.keys(options_item).length > 0) {
            options_item.index = k + 1;
            options.push(options_item);
            check = true;
          }
        }

        if (options.length > 0) {
          missing.options = options;
        }

        let condtions = [];

        for (let k = 0; k < tempElements[i]?.data?.conditions?.length; k++) {
          let condition_check = false;
          let conditions_item = {};

          let condition = tempElements[i]?.data?.conditions[k];
          contentState = stateFromHTML(condition?.conditionDescription);
          plainText = contentState.getPlainText();

          let c_desc = plainText.replace(regex, "");
          contentState = stateFromHTML(condition?.conditionDescription_ES);
          plainText = contentState.getPlainText();

          let c_desc_ES = plainText.replace(regex, "");

          if (c_desc == "" || condition?.conditionDescription == "") {
            conditions_item.description = "Description is Missing.";
            check = true;
            condition_check = true;
          }
          if (condition?.conditionDescription_ES == "" || c_desc_ES == "") {
            conditions_item.description_ES = "Spanish Description is Missing.";
            check = true;
            condition_check = true;
          }
          if (condition?.conditionLabel == "") {
            conditions_item.label = "Label is Missing.";
            check = true;
            condition_check = true;
          }
          if (condition?.conditionLabel_ES == "") {
            conditions_item.label_ES = "Spanish Label is Missing.";
            check = true;
            condition_check = true;
          }

          let condtion_options = [];

          for (
            let j = 0;
            j < tempElements[i]?.data?.conditions[k]?.conditionOptions?.length;
            k++
          ) {
            let condition_option_item = {};

            let option =
              tempElements[i]?.data?.conditions[k]?.conditionOptions[j];

            if (option?.text == "") {
              condition_option_item.text = `Text is Missing.`;
            }

            if (option?.text_ES == "") {
              condition_option_item.text_ES = `Spanish Text is Missing.`;
              if (!condition_option_item.text) {
                condition_option_item.text = option?.text;
              }
            }

            if (Object.keys(condition_option_item).length > 0) {
              condition_option_item.index = j + 1;
              condtion_options.push(condition_option_item);
            }
          }

          if (condtion_options.length > 0) {
            conditions_item.options = condtion_options;
            check = true;
            condition_check = true;
          }

          if (condition_check && condition?.conditionLabel != "") {
            conditions_item.label = condition?.conditionLabel;
          }
          if (Object.keys(conditions_item).length > 0) {
            conditions_item.index = k + 1;
            condtions.push(conditions_item);
          }
        }
        if (condtions.length > 0) {
          missing.conditions = condtions;
        }
      }

      if (check) {
        arr.push(missing);
      }
    }
    setNodes(arr);
    
    setViewNodeModal(true);
  };
  
  const CustomNodeComponent = (props) => {
    const { id, data } = props;
    const { elements } = useAppContext();
    const onCustomNodeClick = (event, node) => {
      // console.log('onNodeClick',event);
      let cloneTemp = [...elements];
      if (cloneTemp.length) {
        let cloneTemp = [...elements];
        let allOptions = [];
        let allEle = cloneTemp.filter((ii) => ii?.source == node.id);
        for (let index = 0; index < cloneTemp.length; index++) {
          const element = cloneTemp[index];
          if (element?.source == node.id) {
            if (!node?.data?.child?.length && !node?.data?.conditions?.length) {
              let findNodinx = cloneTemp.findIndex((ii) => ii?.source == node.id);
              cloneTemp.splice(findNodinx, 1);
            } else if (
              node?.data?.child?.length ||
              node?.data?.conditions?.length
            ) {
              // let allOptions = []
              allOptions = allOptions.concat(node?.data?.child);
              node?.data?.conditions?.forEach((contItem) => {
                allOptions = allOptions.concat(contItem?.conditionOptions);
              });
             
            }
          }
        }
        let unqiueArr = allOptions.filter((v, i, a) => a.indexOf(v) === i);
        let filEle = cloneTemp.filter((fil) => fil?.source == node.id);
        let ele = [];
        filEle.filter((aa) => {
          unqiueArr.filter((bb) => {
            if (!unqiueArr.some((item) => item.targetNode === aa.target)) {
              ele.push(aa);
            }
          });
        });
        ele = [...new Set(ele)];
        if (ele.length) {
          for (let index = 0; index < ele.length; index++) {
            const elmt = ele[index];
            let clonTmp = cloneTemp.find((ii) => ii?.id == elmt.id);
            if (clonTmp) {
              let optionRemoveInd = cloneTemp.findIndex(
                (ii) => ii?.id == clonTmp?.id
              );
    
              if (optionRemoveInd > -1) {
                cloneTemp.splice(optionRemoveInd, 1);
              }
            }
          }
        }
    
        setElements(cloneTemp);
        setTobeEdited(node);
        setEdit(!showEdit);
      }
    };

    return (
      <>
        <div
          className={`${styles.customNodeStyles} ${styles.addNodeAnimation} search`}
        >
          <div className={styles.options}>
            <div className={styles.editDeleteContainer}>
              <div className={styles.deleteIconContainer}>
                <p>
                  <DeleteOutlineOutlinedIcon
                    onClick={() => setDeleteItem(data)}
                    className={styles.deleteIconStyles}
                  />
                </p>
              </div>
              <div className={styles.editContainer}>
                <div className={styles.emptyDiv}></div>
              </div>
              <div className={styles.editIconContainer}>
                <>
                  <EditIcon
                    onClick={() => {
                      // handleEditNode(props.id)
                      onCustomNodeClick(
                        {},
                        elements.find((ii) => ii?.id == id)
                      );
                      // let as=
                      //   console.log(elements,data,'props.id',as);
                    }}
                    className={styles.editIconStyles}
                  />
                </>
              </div>
            </div>
          </div>
          <Handle
            type="target"
            position="top"
            className={styles.handleStyles}
          />
          <strong className={styles.labelStyles}>Label:</strong>
          <p className={`${styles.paragraphStyles} pargraph`}>{data?.label}</p>
          {/* <strong className={styles.labelStyles}>Description:</strong>
        <p className={styles.paragraphStyles}>{data?.description}</p> */}
          <Handle
            type="source"
            position="bottom"
            id="a"
            className={styles.handleStyles}
          />
        </div>
      </>
    );
  };
  const nodeTypes = {
    special: CustomNodeComponent,
  };
  const handleFiles = (e) => {
    let file = e.target.files[0];
    if (file?.type?.includes("json")) {
      if (e.target.files.length) {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = (fileRead) => {
          let ReadFile = JSON.parse(fileRead.target.result);
          if (ReadFile[0]?.isJson) {
            setElements(ReadFile);
            toast.success("File Successfully Import", {
              theme: "colored",
              hideProgressBar: true,
            });
          } else {
            toast.error("Invalid File", {
              theme: "colored",
              hideProgressBar: true,
            });
          }

          // setFiles(e.target.result);
        };
      }
    } else {
      toast.error("Invalid File", { theme: "colored", hideProgressBar: true });
    }
  };
  return (
    <>
      <ReactFlow
        elements={elements}
        onElementsRemove={onElementsRemove}
        nodesConnectable={false}
        onLoad={onLoad}
        snapToGrid={true}
        snapGrid={[15, 15]}
        onEdgeUpdate={onEdgeUpdate}
        onLoad={setRfInstance}
        nodeTypes={nodeTypes}
        onNodeDoubleClick={onNodeClick}
        edgeTypes="steps"
        deleteKeyCode={false}
        className={styles.container}
      >
        <MiniMap
          nodeStrokeColor={(n) => {
            if (n.style?.background) return n.style.background;
            if (n.type === "input") return "#0041d0";
            if (n.type === "output") return "#ff0072";
            if (n.type === "default") return "#1a192b";

            return "#eee";
          }}
          nodeColor={(n) => {
            if (n.style?.background) return n.style.background;

            return "#fff";
          }}
          nodeBorderRadius={2}
        />
        <Controls
          showInteractive={false}
          className="react-flow__controls"
          style={{
            background: "red !important",
            backgroundColor: "red !important",
          }}
        />
        <Background
          color="#F9F3E6"
          gap={16}
          className={styles.backgroundColor}
        />
      </ReactFlow>
      <Search elements={elements} />
      <ConfirmationDialog
        open={open}
        handleClose={handleCloseToggle}
        handleYes={handleYes}
      />
      <div className={styles.addNodeContainer}>
        <div
          className={`${styles.buttonStyles} ${styles.addNodeAnimation}`}
          onClick={handleAddModal}
        >
          <img src={Subtraction} className={styles.imageStyles} />
          <p className={styles.paragraphWithoutMargin}>+ Add Node</p>
        </div>
        <div
          className={`${styles.buttonStyles} ${styles.addNodeAnimation}`}
          onClick={openViewNodesModal}
        >
          <img src={Subtraction} className={styles.imageStyles} />
          <p className={styles.paragraphWithoutMargin}>Detail</p>
        </div>
        <div
          className={`${styles.buttonStyles} ${styles.addNodeAnimation}`}
          onClick={onSave}
        >
          <img src={Save} className={styles.imageStyles} />
          <p className={styles.paragraphWithoutMargin}>Save</p>
        </div>
        <div className={`${styles.buttonStyles} ${styles.addNodeAnimation}`}>
          <a href="/" className={styles.anchorStyles}>
            <img src={Reload} className={styles.imageStyles} />
            <p className={styles.paragraphWithoutMargin}>Reload</p>
          </a>
        </div>
        <div className={`${styles.buttonStyles} ${styles.addNodeAnimation}`}>
          <a
            className={styles.anchorStyles}
            href="https://openborders.io/"
            target="_blank"
          >
            <img src={Play} />
            <p className={styles.paragraphWithoutMargin}>Play</p>
          </a>
          {""}
        </div>
        <div className={`${styles.buttonStyles} ${styles.addNodeAnimation}`}>
          <div className={styles.downloadContainer}>
            <a
              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(elements)
              )}`}
              download="filename.json"
              className={styles.anchorStyles}
            >
              <img src={Download} className={styles.imageStyles} />
              <p style={{ padding: 0, margin: 0 }}>Download</p>
            </a>
          </div>
        </div>
        <div className={`${styles.buttonStyles} ${styles.addNodeAnimation}`}>
          <div className={styles.downloadContainer}>
            <input
              type={"file"}
              onChange={handleFiles}
              id="file"
              className={styles.fileNone}
            />
            <label
              htmlFor="file"
              // href={`data:text/json;charset=utf-8,${encodeURIComponent(
              //   JSON.stringify(elements)
              // )}`}
              // download="filename.json"
              className={styles.anchorStyles}
            >
              <img
                src={Download}
                className={`${styles.imageStyles} ${styles.rotate}`}
              />
              <p style={{ padding: 0, margin: 0 }}>Import</p>
            </label>
          </div>
        </div>
        <div className={`${styles.buttonStyles} ${styles.addNodeAnimation}`}>
          <a
            href="javascript:void(0)"
            onClick={() => {
              setLoginUser(null);
              localStorage.removeItem("userToken");
            }}
            className={styles.anchorStyles}
          >
            <Logout />
            <p className={styles.paragraphWithoutMargin}>Logout</p>
          </a>
          {""}
        </div>
      </div>
      <AddNode
        modal={modal}
        handleClose={handleAddModal}
        elements={elements}
        handleAdd={handleAdd}
        setUpdateIndicator={setUpdateIndicator}
      />
      <EditNode
        modal={showEdit}
        handleClose={() => {
          setEdit(false);
        }}
        tobeEdited={tobeEdited}
        setTobeEdited={setTobeEdited}
        handleEdit={handleEdit}
        elements={elements}
        setElements={setElements}
        handleAddModal={handleAddModal}
        nodeSummary={nodeSummary}
        setNodeSummary={setNodeSummary}
        setUpdateIndicator={setUpdateIndicator}
      />
      <ViewNodes
        modal={viewNodeModal}
        handleClose={() => {
          setViewNodeModal(false);
        }}
        nodes={nodes}
        elements={elements}
      />
      {loader && <Loader />}
    </>
  );
};

const onLoad = (reactFlowInstance) => {
  reactFlowInstance.fitView();
};

export default OverviewFlow;

//https://617948a2e7ca7b00b38c3b7e--border-crossing-8e5e5.netlify.app/
// https://617a854ded34960a62ccc75d--border-crossing-8e5e5.netlify.app
