import React, { useState, useEffect } from "react";
import styles from "./addCondition.module.scss";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from "uuid";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import IfConditional from "../../shared/ifConditional/ifConditional";
import { TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ActionLink from "../actionLink/actionLink";
import {
  CustomSearch,
  CustomSummary,
  CustomEditor,
  CloseIcon,
  computeCondition,
  computeDuplicateOption,
  useAppContext
} from "../../shared";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import CustomButton from '../button'
import SelectConditionModal from './selectConditionModal'
const AddCondition = ({ modal, handleAdd, elements, handleClose, tobeEdited, setElements, setUpdateIndicator }) => {
  const { addConditionInList,checkConditionName } = useAppContext()
  const [condition, setCondition] = useState([
    {
      id: uuidv4(),
      andOr: "",
      nodeId: "",
      optionId: "",
      options: [],
      extraNodes: [],
      showOption: false,
      showAddOr: false,
    },
  ]);
  const [optionTitle, setOptionTitle] = useState("");
  const [optionTitle_es, setOptionTitleES] = useState("");
  const [conditionName, setConditionName] = useState("");
  const [optionTarget, setOptionTarget] = useState("");
  const [optionsInCondition, setOptionsInCondition] = useState([]);
  const [options, setOptions] = useState("");
  const [targetNode, setTargetNode] = useState("");
  const [label, setLabel] = useState("");
  const [label_ES, setLabelES] = useState("");
  const [description, setDescription] = useState("");
  const [catagory, setCatagory] = useState("");
  const [summaryDesc, setSummaryDesc] = useState("");
  const [summaryCheck, setSummaryCheck] = useState(false);
  const [scheduleBool, setScheduleBool] = useState(false)
  const [immigrationBool, setimmigrationBool] = useState(false);
  const [modalres, setModal] = useState(false);
  const [linkArr, setLinkArr] = useState([]);
  const [selectConditionModal,setSelectConditionalModal] = useState(false)
  const [spanisCheck, setSpanishCheck] = useState(false);
  const [editorState, setContentState] = useState(EditorState.createEmpty());
  const [editorSpanishState, setSpanishContentState] = useState(EditorState.createEmpty());
  const [contentStateSummary, setContentStateSummary] = useState(EditorState.createEmpty());
  const [SpanishContentStateSummary, setSpanishContentStateSummary] = useState(EditorState.createEmpty());

  useEffect(() => {
    setOptions(tobeEdited.data.child);
  }, []);

  const onEditorStateChange = (editor) => {
    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setContentState(editor);
  };
  const onEditorSpanishStateChange = (editor) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setSpanishContentState(editor);
  };
  const onStateChangeSummary = (editor) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setContentStateSummary(editor);
  };
  const onStateChangeSummaryInSpanish = (editor) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setSpanishContentStateSummary(editor);
  };

  const handleAddCondition = () => {
    setCondition([
      ...condition,
      {
        id: uuidv4(),
        andOr: "",
        nodeId: "",
        optionId: "",
        options: [],
        extraNodes: [],
        showOption: false,
        showAddOr: false,
      },
    ]);
  };
  const handleAddSubCondition = (optionIndex) => {
    let temp = [...condition];
    temp[optionIndex].extraNodes.push({
      id: uuidv4(),
      nodeId: "",
      optionId: "",
      nodeLabel: "",
      options: [],
      showOption: false,
    });
    setCondition(temp);
  };
  //selecting node, and adding options/child to the option array
  const handleOptionSelect = (selectedItem, optionsIndex) => {
    let temp = elements.filter((item, index) => item.id === selectedItem.id);
    let newTemp = [...condition];
    let optionsInCondition = [];
    temp[0]?.data?.conditions?.forEach((item, index) => {
      optionsInCondition = [...optionsInCondition, ...item.conditionOptions];
    });
    let uniqueOptions = [];
    optionsInCondition.forEach((item, index) => {
      let find = uniqueOptions.find(
        (newItem, newIndex) => newItem.id === item.id
      );
      if (!find) {
        uniqueOptions.push(item);
      }
    });
    newTemp[optionsIndex].nodeId = temp[0].id;
    newTemp[optionsIndex].options = [...temp[0].data.child, ...uniqueOptions];
    setCondition(newTemp);
  };
  const handleSubOptionSelect = (selectedItem, optionIndex, optionSubIndex) => {
    let newTemp = [...condition];
    let optionsInCondition = [];
    selectedItem?.data?.conditions?.forEach((item, index) => {
      optionsInCondition = [...optionsInCondition, ...item.conditionOptions];
    });
    let uniqueOptions = [];
    optionsInCondition.forEach((item, index) => {
      let find = uniqueOptions.find(
        (newItem, newIndex) => newItem.id === item.id
      );
      if (!find) {
        uniqueOptions.push(item);
      }
    });
    newTemp[optionIndex].extraNodes[optionSubIndex].nodeId = selectedItem.id;
    newTemp[optionIndex].extraNodes[optionSubIndex].nodeLabel =
      selectedItem.data.label;
    newTemp[optionIndex].extraNodes[optionSubIndex].options = [
      ...selectedItem.data.child,
      ...uniqueOptions,
    ];
    setCondition(newTemp);
  };

  const handleNodeOptionSelect = (e, optionIndex) => {
    let newTemp = [...condition];
    newTemp[optionIndex].optionId = e.target.value;
    setCondition(newTemp);
  };
  const handleSubNodeOptionSelect = (e, optionIndex, subOptionIndex) => {
    let newTemp = [...condition];
    newTemp[optionIndex].extraNodes[subOptionIndex].optionId = e.target.value;
    setCondition(newTemp);
  };
  const hanldeAndOr = (e, optionsIndex) => {
    let newTemp = [...condition];
    newTemp[optionsIndex].andOr = e.target.value;
    setCondition(newTemp);
  };
  const toggleOption = (index) => {
    let newTemp = [...condition];
    newTemp[index].showOption = !newTemp[index].showOption;
    setCondition(newTemp);
  };
  const toggleSubOption = (optionIndex, subOptionIndex) => {
    let newTemp = [...condition];
    newTemp[optionIndex].extraNodes[subOptionIndex].showOption =
      !newTemp[optionIndex].extraNodes[subOptionIndex].showOption;
    setCondition(newTemp);
  };
  const toggleAddOr = (index) => {
    let newTemp = [...condition];
    newTemp[index].showAddOr = !newTemp[index].showAddOr;
    setCondition(newTemp);
  };
  const onSubmit = () => {

    let tempDescription = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    let tempESDescription = draftToHtml(
      convertToRaw(editorSpanishState.getCurrentContent())
    );
    var final_Condition = computeCondition(condition);
    let temp = [...elements];
    let index = elements.findIndex((item) => item.id === tobeEdited.id);
    let conItem = {
      id: uuidv4(),
      conditionLabel: label,
      conditionName:conditionName ? conditionName:"",
      conditionLabel_ES: label_ES,
      conditionDescription: tempDescription,
      conditionDescription_ES: tempESDescription,
      condition: final_Condition,
      conditionOptions: optionsInCondition,
      immigrationBool: immigrationBool,
      scheduleBool: scheduleBool,
      actionLink: linkArr
    }
    temp[index].data.conditions = [
      ...temp[index].data.conditions,
      conItem
      ,
    ];
    addConditionInList(conItem)
    setElements(temp);
    setOptions([]);
    setTargetNode("");
    setCondition([
      {
        id: uuidv4(),
        andOr: "",
        nodeId: "",
        optionId: "",
        options: [],
        extraNodes: [],
        showOption: false,
        showAddOr: false,
      },
    ]);
    setDescription("");
    setContentState(EditorState.createEmpty());
    setSpanishContentState(EditorState.createEmpty());
    setOptionsInCondition([]);
    setOptionTitle("");
    setOptionTarget("");
    setLabel("");
    setLabelES("");
    setConditionName("");
    setimmigrationBool(false);
    setScheduleBool(false);
    // setContentState("");
    toast.success("Condition added successfully!", { theme: "colored", hideProgressBar: true });
    // if(conditionName){
    //   if(checkConditionName(conditionName)){
    //   }else{
    //     toast.error("Condition Name Must be Unique!", { theme: "colored", hideProgressBar: true });
    //   } 
      
    // }else{
    //   toast.error("Condition Name is Required!", { theme: "colored", hideProgressBar: true });
    // }
    
  };
  const addOptionInCondition = () => {
    setUpdateIndicator(true)
    let temp = computeDuplicateOption({
      conditionOptions: optionsInCondition,
      tobeEdited: tobeEdited,
      summaryDesc: draftToHtml(
        convertToRaw(contentStateSummary.getCurrentContent())
      ),
      summaryDesc_ES: draftToHtml(
        convertToRaw(SpanishContentStateSummary.getCurrentContent())
      ),
      catagory: catagory,
      optionTarget: optionTarget ? optionTarget : null,
      optionLabel: optionTitle,
      optionLabel_ES: optionTitle_es,
    });

    setOptionsInCondition(temp);
    temp = [...elements];
    if (optionTarget) {
      temp.push({
        id: uuidv4(),
        source: tobeEdited.id,
        target: optionTarget,
        animated: false,
        sourceHandle: "a",
        targetHandle: null,
        arrowHeadType: "arrowclosed",
      });
    }
    setElements(temp);
    setOptionTitle("");
    setOptionTitleES("");
    setSpanishContentStateSummary(EditorState.createEmpty());
    setContentStateSummary(EditorState.createEmpty());
    setOptionTarget("");
    setCatagory("")

  };

  //action link
  const handleAddModal = () => {
    setModal(!modalres)
  }

  const handleDeleteOption = (item) => {
    let newTemp = [...elements];
    if (item.targetNode !== null) {
      let index = elements.findIndex(
        (newItem, index) =>
          newItem.source === tobeEdited.id && newItem.target === item.targetNode
      );
      newTemp.splice(index, 1);
    }

    setElements(newTemp);
    let temp = [...optionsInCondition];
    temp = temp.filter((newItem, index) => newItem.id !== item.id);
    setOptionsInCondition(temp);
  };
  const handleRemoveNode = (index) => {
    let temp = [...condition];
    temp.splice(index, 1);
    setCondition(temp);
  };
  const handleRemoveSubNode = (optionIndex, index) => {
    let temp = [...condition[optionIndex].extraNodes];
    temp.splice(index, 1);
    let newTemp = [...condition];
    newTemp[optionIndex].extraNodes = temp;
    setCondition(newTemp);
  };
  const handleConditionClose = ()=> setSelectConditionalModal(false)
  const openConditionModal = ()=> setSelectConditionalModal(true)
  return (
    <div className={styles.container}>
      <DialogContent>
        {/* <CloseIcon handleClose={handleClose} /> */}
        <IfConditional
          condition={condition}
          hanldeAndOr={hanldeAndOr}
          handleOptionSelect={handleOptionSelect}
          options={options}
          handleAddCondition={handleAddCondition}
          elements={elements}
          tobeEdited={tobeEdited}
          handleNodeOptionSelect={handleNodeOptionSelect}
          toggleOption={toggleOption}
          toggleAddOr={toggleAddOr}
          handleSubOptionSelect={handleSubOptionSelect}
          handleAddSubCondition={handleAddSubCondition}
          toggleSubOption={toggleSubOption}
          handleSubNodeOptionSelect={handleSubNodeOptionSelect}
          handleRemoveNode={handleRemoveNode}
          handleRemoveSubNode={handleRemoveSubNode}
          setConditionName={setConditionName}
          conditionName={conditionName}
          openConditionModal={openConditionModal}
        />

        {condition.length > 0 && (
          <>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={spanisCheck}
                  onClick={() => setSpanishCheck(!spanisCheck)}
                />
              }
              label="Spanish"
            /> */}
             <CustomButton handle={()=>{ setSpanishCheck(!spanisCheck);}} label={"Edit Spanish Language"} active={spanisCheck} />

            <div className={styles.margin}>
              <InputLabel id="label1" className={styles.labelStyles}>
                Enter Label:
              </InputLabel>
              <TextField
                value={label}
                margin="dense"
                id="label1"
                label="Enter Label"
                type="text"
                fullWidth
                variant="outlined"
                className={styles.minWidth}
                onChange={(e) => setLabel(e.target.value)}
              />
              {
                spanisCheck && <>
                  <InputLabel id="label1" className={styles.labelStyles}>
                    Introducir etiqueta:
                  </InputLabel>
                  <TextField
                    value={label_ES}
                    margin="dense"
                    id="label1"
                    label="Introducir etiqueta"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className={styles.minWidth}
                    onChange={(e) => setLabelES(e.target.value)}
                  />

                </>
              }


            </div>
            <div className={styles.margin}>
              <InputLabel id="label1" className={styles.labelStyles}>
                Enter Description:
              </InputLabel>
            </div>
            <CustomEditor
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
            />
            {
              spanisCheck && <>
                <div className={styles.margin}>
                  <InputLabel id="label1" className={styles.labelStyles}>
                    Introduzca la descripción:
                  </InputLabel>
                </div>
                <CustomEditor
                  editorState={editorSpanishState}
                  onEditorStateChange={onEditorSpanishStateChange}
                  placeholderText={'Introduzca la descripción'}
                />

              </>
            }
            {/* <div className={styles.defaultMarginStyles}>
          <FormControlLabel
            control={
              <Checkbox
                checked={scheduleBool}
                onClick={() => {
                  setScheduleBool(!scheduleBool);
                }}
              />
            }
            label="Select if you want schedule a consultation"
          />
        </div>
        <div className={styles.defaultMarginStyles}>
          <FormControlLabel
            control={
              <Checkbox
                checked={immigrationBool}
                onClick={() => {
                  setimmigrationBool(!immigrationBool);
                }}
              />
            }
            label="Select if you want to begin a immigration process"
          />
        </div> */}
            <div className={styles.nextContainer}>
              <Button
                variant="contained"
                className={`${styles.nextStyles} ${styles.actionbutton}`}
                onClick={handleAddModal}
              >
                Action Link
              </Button>

            </div>

            <div className={styles.optionContainer}>
              {optionsInCondition.length > 0 &&
                optionsInCondition.map((item, index) => {
                  return (
                    <div key={index} className={styles.optionInnerContainer}>
                      <div className={styles.optionTextStyles}>
                        <p>{item.text}</p>
                      </div>
                      <div className={styles.iconSubContainer}>
                        <div
                          className={styles.deleteIconContainer}
                          onClick={() => handleDeleteOption(item)}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={styles.margin}>
              <InputLabel id="label3" className={styles.labelStyles}>
                Enter Option Title:
              </InputLabel>
              <TextField
                value={optionTitle}
                margin="dense"
                id="label3"
                label="Option Title"
                type="text"
                fullWidth
                variant="outlined"
                className={styles.minWidth}
                onChange={(e) => setOptionTitle(e.target.value)}
              />
              {
                spanisCheck && <>
                  <InputLabel id="label3" className={styles.labelStyles}>
                    Ingrese el título de la opción:
                  </InputLabel>
                  <TextField
                    value={optionTitle_es}
                    margin="dense"
                    id="label3"
                    label="Título de la opción"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className={styles.minWidth}
                    onChange={(e) => setOptionTitleES(e.target.value)}
                  />

                </>
              }
              <CustomSearch
                handleOptionSelect={(item, optionIndex) => {
                  setOptionTarget(item.id);
                }}
                optionIndex="dummy value"
                showAllNodes={true}
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={summaryCheck}
                    onClick={() => {
                      setSummaryCheck(!summaryCheck);
                    }}
                  />
                }
                label="Check if you want to add summary"
              /> */}
              <div className={styles.margin}>

               <CustomButton label={"Add text to View Your Evaluation"} active={summaryCheck} handle={() => {
                  setSummaryCheck(!summaryCheck);
                }} />
              </div>
              {summaryCheck ? (
                <>
                  <CustomSummary
                    catagory={catagory}
                    setCatagory={setCatagory}
                    categoryBool={true}
                    summaryDesc={contentStateSummary}
                    onEditorStateChange={onStateChangeSummary}
                    placeholderText={"Enter Summary Description"}
                    title={"Enter Summary Description:"}
                  />
                  {
                    spanisCheck && <CustomSummary
                      catagory={catagory}
                      setCatagory={setCatagory}
                      categoryBool={false}
                      summaryDesc={SpanishContentStateSummary}
                      onEditorStateChange={onStateChangeSummaryInSpanish}
                      placeholderText={"Ingrese la descripción resumida"}
                      title={"Ingrese la descripción resumida:"}
                    />
                  }
                </>
              ) : null}
            </div>
            <div>
              <Button
                variant="contained"
                className={styles.nextStyles}
                onClick={addOptionInCondition}
              >
                Add Option
              </Button>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={styles.nextStyles}
          onClick={onSubmit}
        >
          Add Condition
        </Button>
      </DialogActions >
      <SelectConditionModal condition={condition} setCondition={setCondition} setContentState={setContentState} setSpanishContentStateSummary={setSpanishContentStateSummary} setContentStateSummary={setContentStateSummary} setSpanishContentState={setSpanishContentState} setLabel={setLabel} setLabelES={setLabelES} setOptionsInCondition={setOptionsInCondition}  modal={selectConditionModal} handleClose={handleConditionClose} />

      <ActionLink
        modal={modalres}
        linkArr={linkArr}
        setLinkArr={setLinkArr}
        handleClose={handleAddModal}
      />
    </div>
  );
};
export default AddCondition;
// https://61853e6165cf050ea3a66976--border-crossing-8e5e5.netlify.app
