import Login from '../login/login'
import OverviewFlow from '../reactFlow/overViewFlow'
 const publicRoute = [{
    title:"Login",
    path:"/",
    component:Login
}]
 const privateRoute = [{
    title:"Admin",
    path:"/",
    component:OverviewFlow
}]
export{
    privateRoute,
    publicRoute 
}