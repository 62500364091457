import React, { useState } from "react";
import styles from "../login/login.module.scss";
import { LoginService } from '../../shared/services';
import Loader from "../../shared/loader/loader";
import { toast } from "react-toastify";
import { useAppContext } from "../../shared";
const Login = () => {
  const { setLoginUser } = useAppContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true);
    LoginService({
      email: email,
      password: password,
    })
      .then(({ data }) => {
        setLoader(false);
        if (data.status) {
          console.log('data',data.data);

          // localStorage.setItem("userToken", JSON.stringify({ user: "ZodJCp5rFG0og57HPxmx7BGtOXmUdcSKH2bGd8wlxOQ=", password: "TRW3uzoDQ+Ix4ueGVPHLHVsOKgTZXV0kacRA+zgmh60=" }))
          localStorage.setItem("userToken", JSON.stringify({ user: data?.data?.user, token: data?.data?.access_token }))
          // setLoginUser({ user: "ZodJCp5rFG0og57HPxmx7BGtOXmUdcSKH2bGd8wlxOQ=", password: "TRW3uzoDQ+Ix4ueGVPHLHVsOKgTZXV0kacRA+zgmh60=" })
          setLoginUser({ user: data?.data?.user, token: data?.data?.access_token })
        }
        else {
          toast.error(data?.message, { theme: "colored", hideProgressBar: true })
        }

      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.message, { theme: "colored", hideProgressBar: true })

      });
  };

  return (
    <div className={styles.mains}>
      <h1 className={styles.heading}>Admin Panel Open Borders</h1>
      <p className={styles.paragraph}>Enter your details to proceed further</p>

      <div className={`${styles.container} ${styles.authContainer}`}>
        <form className={styles.signinForm} onSubmit={handleSubmit}>
          <label className="label">Email</label>
          <input
            value={email}
            className={styles.signinInput}
            type="email"
            placeholder="catherine.shaw@gmail.com"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className="label">Password</label>
          <input
            value={password}
            className={styles.signinInput}
            type="password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className={`${styles.btn} ${styles.btnAuth}`}>Log In</button>
        </form>
      </div>
      {
        loader && <Loader />
      }

    </div>
  );
};

export default Login;