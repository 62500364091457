import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier,convertFromHTML,ContentState,convertToRaw,getEntityRange,getSelectionEntity,RichUtils } from 'draft-js';
import { stateFromHTML } from "draft-js-import-html";
import htmlToDraft from 'html-to-draftjs';;

function Placeholders(props){
const [state,setState] = useState({
  open: false
})
const [title,setTitle] = useState("");
const [link,setLink] = useState("");
  

  const openPlaceholderDropdown = () => setState({
    ...state,
    open: !state.open})

 const  addPlaceholder = () => {
   
    const { editorState, onChange } = props;
   //=======================================
    insertLink('https://www.google.com','klajdsf;lkasdjfakl',editorState,onChange)
      
    
    }
  function customChunkRenderer(nodeName, node) {
    if (nodeName === 'a') {
      return {
        type: 'Link',
        mutability: 'MUTABLE',
        data: { // Pass whatever you want here (like id, or classList, etc.)
          innerText: node.innerText,
          innerHTML: node.innerHTML,
          id:"zain"
        }
      };
    }
    if (nodeName === 'script') {
      return {
        type: 'SCRIPT',
        mutability: 'MUTABLE',
        data: { // Pass whatever you want here (like id, or keyEvents, etc.)
          innerText: node.innerText,
          innerHTML: node.innerHTML
        }
      };
    }
    return node;
  }

    return (<>
      <div className="rdw-link-wrapper" aria-label="rdw-link-control">
        <div onClick={()=>openPlaceholderDropdown()}  className="rdw-option-wrapper rdw-option-disabled" title="Unlink">
        Link
          
         
        </div>
        {
              state.open && <div className={`rdw-link-modal`}>
               {/* { this.listItem} */}
               <label>Title</label>
               <input id="linkTitle" value={title} className='rdw-link-modal-input' onChange={(e)=>setTitle(e.target.value)} name="linkTitle" />
               <label>Link</label>
               <input id="linkTitle" value={link} className='rdw-link-modal-input' onChange={(e)=>setLink(e.target.value)} name="linkTitle" />
               {/* <label class="rdw-link-modal-target-option" for="openLinkInNewWindow"><input id="openLinkInNewWindow" type="checkbox" value="_blank" /><span>Open link in new window</span></label> */}
               <span class="rdw-link-modal-buttonsection"><button class="rdw-link-modal-btn" disabled="" onClick={()=>{
                 setState({
                   ...state,
                   open: !state.open
                 })
                 addPlaceholder()}}>Add</button><button class="rdw-link-modal-btn" onClick={openPlaceholderDropdown}>Cancel</button></span>
             </div>
          }
      </div>
      
      </>
    );
  
}
export const insertLink = (
  link,
  text,
  editorState,
  onChange
) => {
  
  const contentStateWithEntity = editorState
    .getCurrentContent()
    .createEntity("LINK", "MUTABLE",{ // Pass whatever you want here (like id, or classList, etc.)
      url: link,
      text,
      rel:"zain"
    } );
    // { url: link, text,id:"zain" }
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    text,
    editorState.getCurrentInlineStyle(),
    entityKey
  );
console.log('contentStateWithEntity',contentStateWithEntity);
  // const newEditorState = EditorState.set(editorState, {
  //   currentContent: contentStateWithEntity
  // });
  // const newEditorStateWithLink = RichUtils.toggleLink(
  //   newEditorState,
  //   newEditorState.getSelection(),
  //   entityKey
  // );

 
    // EditorState.push(editorState, contentState, "insert-characters");
    onChange(
        EditorState.push(editorState, contentState, "insert-characters")
      )
  
};

export default Placeholders;
