const labelParser = (elements, nodeId) => {
  if (nodeId !== "AND" && nodeId !== "AND NOT" && nodeId !== "OR") {
    if (nodeId.includes("&&")) {
      //split nodeId and option Id
      let temp = nodeId.split("&&");
      let finalString = "";
      let index = elements?.findIndex((item, index) => item.id === temp[0]);
      finalString = elements[index]?.data?.label;
      //compute all options options
      let tempChild = elements[index]?.data?.child;
      elements[index].data.conditions.forEach((item, index) => {
        tempChild = [...tempChild, ...item.conditionOptions];
      });
      let filteredChild = tempChild.filter(
        (item, index) => item.id === temp[1]
      );
      if (filteredChild.length > 0) {
        finalString = finalString + `&& ${filteredChild[0].text}`;
        return finalString;
      } else {
        return finalString;
      }
    } else {
      let index = elements.findIndex((item, index) => item.id === nodeId);
      return elements[index]?.data?.label;
    }
  } else {
    return `${nodeId}`;
  }
};
const getLabel = (elements, nodeId) => {
  if (nodeId.includes("||")) {
    let temp = nodeId.split("||");
    let finalString = "";
    temp.forEach((item, index) => {
      let tempString = getLabel(item);
      if (index + 1 === temp.length) {
        finalString = finalString + tempString;
      } else {
        finalString = finalString + tempString + " || ";
      }
    });
    return finalString;
  } else {
    return labelParser(elements, nodeId);
  }
};
const prepareViewCondition = ({ tobeEdited }) => {
  var newObj = JSON.parse(JSON.stringify(tobeEdited));
  tobeEdited.data.conditions?.forEach((item, index) => {
    let temp = tobeEdited.data.conditions[index].condition;
    if (typeof temp == 'string') {
      temp = temp?.split(">");
      newObj.data.conditions[index].condition = temp;
    }
  });

  tobeEdited?.data?.optionCondition?.forEach((item, index) => {
    let temp1 = tobeEdited.data.optionCondition[index].condition;
    temp1 = temp1?.split(">");
    newObj.data.optionCondition[index].condition = temp1;
  });
  return newObj;
};
export { prepareViewCondition, getLabel, labelParser };
