import "./App.css";
import OverviewFlow from "./components/reactFlow/overViewFlow";
import Login from "./components/login/login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppStore from "./shared/contextApi/index";
import {useEffect} from 'react'
import {initialConfig} from './shared'
import Loader from "./shared/loader/loader";
import AuthRoute from "./components/routes/authRoute";

function App() {
  // useEffect(()=>{
  //   initialConfig()
  // })
  return (
    <div className="App">
      <AppStore>
        {/* <Routes> */}

        <AuthRoute />
        {/* </Routes> */}
      
        <ToastContainer />
        {/* <Login /> */}
        
        {/* <OverviewFlow /> */}
      </AppStore>
    </div>
  );
}

export default App;
