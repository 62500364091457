import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "./closeIcon.module.scss";
const CloseIcon = ({ handleClose }) => {
  return (
    <div className={styles.iconContainer}>
      <ClearIcon className={styles.iconStyles} onClick={handleClose} />
    </div>
  );
};
export { CloseIcon };
