import React, { useEffect } from 'react'

function Layout(props) {
    useEffect(()=>{
        document.title = props.title + ' | Openborders'
    })
    
    return (
        <props.component />
    )
}

export default Layout
