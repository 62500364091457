import { HTTP_Request } from "../utils/config";
import { Endpoint } from "../utils/endpoint";
const LoginService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(Endpoint.Login, formBody);
};
export{
    LoginService 
}