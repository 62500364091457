import React from "react";
import TextField from "@mui/material/TextField";
import styles from "./search.module.scss";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  input: {
    height: "39px",
    width: "296px",
    background:"#fffaed"
  },
}));
const checkFound = function (id, findVal) {
  let check = false;
  for (let index = 0; index < findVal.length; index++) {
    if (id == findVal[index].id) {
      check = true;
      break;
    }
  }
  return check;
};

const checkCondition = function (search, conditions, label) {
  let check = false;
  for (let index = 0; index < conditions?.length; index++) {
    if (
      conditions[index]?.conditionLabel?.toLocaleLowerCase().includes(search) ||
      conditions[index]?.conditionDescription
        ?.toLocaleLowerCase()
        .includes(search)
    ) {
      check = true;
      break;
    }
  }
  return check;
};
function Sarch({ elements }) {
  const classes = useStyles();
  const handleSearch = (e) => {
    let Sear = e.target.value;
    let findVal = elements?.filter((ii) => {
      let description = ii?.data?.description?.replace(/&lt;/gi, "<");
      if (
        ii?.data?.label
          .toLocaleLowerCase()
          .includes(Sear.toLocaleLowerCase()) ||
        ii?.data?.description
          .toLocaleLowerCase()
          .includes(Sear.toLocaleLowerCase()) ||
        checkCondition(
          Sear.toLocaleLowerCase(),
          ii?.data?.conditions,
          ii?.data?.label.toLocaleLowerCase()
        )
      ) {
        return true;
      }
    });
    console.log(findVal.length, "okok", Sear.length);
    if (findVal?.length && Sear?.length) {
      let doc = document.getElementsByClassName("selectable");
      if (doc.length) {
        for (let index = 0; index < doc.length; index++) {
          const element = doc[index].getAttribute("data-id");
          if (checkFound(element, findVal)) {
            doc[index].children[0].style.backgroundColor = "#fbfccf";
          } else {
            doc[index].children[0].style.backgroundColor = "#c3ccf4";
          }
        }
      }
    } else {
      let docSearch = document.getElementsByClassName("search");
      for (let index = 0; index < docSearch.length; index++) {
        docSearch[index].style.backgroundColor = "#c3ccf4";
      }
    }
    // let doc = document.getElementById(val.id);
    // doc.style.backgroundColor = "yellow";
    // console.log("OKOKOK", findVal);
    // console.log("findVal", findVal);
    // let getDoc = document.getElementsByClassName("search");
    // let newDoc = document.getElementsByClassName("pargraph");
    // if (findVal.length) {
    //   if (newDoc.length) {
    //     for (let index = 0; index < newDoc.length; index++) {
    //       const element = newDoc[index];
    //       const docCard = getDoc[index];
    //       console.log("element 1", docCard);
    //       console.log("element 2", element);

    //       if (
    //         Sear &&
    //         element.textContent
    //           .toLocaleLowerCase()
    //           .includes(Sear.toLocaleLowerCase())
    //       ) {
    //         docCard.style.backgroundColor = "yellow";
    //       } else {
    //         docCard.style.backgroundColor = "#c3ccf4";
    //       }
    //     }
    //     newDoc.forEach((element) => {
    //       console.log("element", element);
    //     });

    //     let resp = newDoc?.filter((ii) =>
    //       ii?.textContent.toLocaleLowerCase().includes(Sear)
    //     );
    //     console.log("resp", resp);
    //   }
    // } else {
    //   for (let index = 0; index < newDoc.length; index++) {
    //     const element = newDoc[index];
    //     const docCard = getDoc[index];
    //     console.log("element", element.textContent);
    //     console.log(
    //       "element.textContent.toLocaleLowerCase().includes(Sear)",
    //       element.textContent.toLocaleLowerCase().includes(Sear)
    //     );
    //     if (
    //       Sear &&
    //       element.textContent
    //         .toLocaleLowerCase()
    //         .includes(Sear.toLocaleLowerCase())
    //     ) {
    //       docCard.style.backgroundColor = "yellow";
    //     } else {
    //       docCard.style.backgroundColor = "#c3ccf4";
    //     }
    //   }
    // }
  };
  return (
    <div className={styles.main}>
      <TextField
      InputProps={{
        className: classes.input,
      }}
        onChange={handleSearch}
        className={styles.search}
        placeholder="Search"
      />
    </div>
  );
}

export default Sarch;
