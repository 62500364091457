import React, { useState } from "react";
import { CloseIcon } from "../../shared/closeIcon/closeIcon";
import Dialog from "@mui/material/Dialog";
import { useAppContext, prepareEditCondition } from "../../shared";
import {  EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import styles from "./addCondition.module.scss";
import { ReactSortable } from "react-sortablejs";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import { Done, CancelOutlined } from "@mui/icons-material";
import CustomButton from '../button/index'
const useStyles = makeStyles(() => ({
  input: {
    borderRadius: 0,
  },
  margin:{
    borderRadius: 0,
    marginBottom:10
  }
}));
const SelectCondition = ({ modal, handleClose, condition, setCondition,setContentState,setSpanishContentState,setSpanishContentStateSummary,setContentStateSummary,setLabelES,setLabel,setOptionsInCondition }) => {
  const { conditionList, elements,checkConditionName,setConditionList } = useAppContext();
  const [search, setSearch] = useState("");
  const [conditionName,setConditionName] = useState("")
  // const [list,setList] = useState(conditionList)
  const [OpenField,setOpenField] = useState(-1);
  const handleCloseField = ()=> setOpenField(-1)
  const handleOpenField = (inx)=> setOpenField(inx)
  const classes = useStyles();
  const conditionSelect = (conItem) => {
    let cloneCondition = [...condition];

    setSearch(conItem?.conditionName);
    let tempConStr = conItem.condition;
    conItem.condition = conItem.toVerify;

    let conditionArr = conItem.condition;

    let temp = prepareEditCondition({
      condition: conditionArr,
      elements: elements,
      editCondition: conItem,
    });
    if (condition.length <= 2) {
    
      if (condition.length <= 1) {
        if (!condition[0]?.nodeId) {
          setCondition(temp);
          updateContentInCondition(conItem)
        } else {
          setCondition(cloneCondition.concat(temp));
        }
      } else {
        if (!condition[0]?.andOr) {
          // setCondition(cloneCondition.concat(temp))
          toast.error("Please Select Condition Type And,Or,And Not", {
            theme: "colored",
            hideProgressBar: true,
          });
        } else {
          setCondition(cloneCondition.concat(temp));
        }
      }
    } else if (condition.length <= 1) {
      if (!condition[condition.length - 1]?.andOr) {
        toast.error("Please Select Condition Type And,Or,And Not", {
          theme: "colored",
          hideProgressBar: true,
        });
      } else {
        setCondition(cloneCondition.concat(temp));
      }
    } else {
      // let testCondition = cloneCondition.concat(temp);
      let checkEmptyCondType = condition.filter(
        (ii) => ii.id != condition[condition.length - 1]?.id && ii.andOr == ""
      );
      if (checkEmptyCondType.length) {
        toast.error("Please Select Condition Type And,Or,And Not", {
          theme: "colored",
          hideProgressBar: true,
        });
      } else {
        setCondition(cloneCondition.concat(temp));
      }
    }
    conItem.condition = tempConStr;
    setSearch("");
    handleClose();
  };
  const updateContentInCondition = (condItem)=>{
    setContentState(changeToHtml(condItem?.conditionDescription))
    setSpanishContentState(changeToHtml(condItem?.conditionDescription_ES))
    setSpanishContentStateSummary(changeToHtml(condItem?.conditionDescription_ES))
    setContentStateSummary(changeToHtml(condItem?.conditionDescription_ES))
    setLabel(condItem?.conditionLabel)
    setLabelES(condItem?.conditionLabel_ES)
    setOptionsInCondition(condItem?.conditionOptions)
    
  }
  const changeToHtml = (content)=>{
    var contentState = stateFromHTML(content?.replace(/&lt;/ig, '<') ? content?.replace(/&lt;/ig, '<') : "");
    return EditorState.createWithContent(contentState);
  }
  const changeConditionName = ()=>{
      if(checkConditionName(conditionName)){
        
            console.log(OpenField);
            
            let cloneConditionList = [...conditionList];
            cloneConditionList[OpenField].conditionName = conditionName
            
            setConditionList(cloneConditionList.reverse())
            setConditionName("")
            handleCloseField()

      }else{
        toast.error("Condition Name Must be Unique!", { theme: "colored", hideProgressBar: true });
      }
  }

  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          minHeight: "600px",
          maxHeight: "600px",
          backgroundColor: "#fffaed",
        },
      }}
    >
      <div style={{ margin: "10px 10px" }}>
        <div>
          <CloseIcon handleClose={handleClose} />
        </div>
        <div className={styles.main}>
          <TextField
            value={search}
            margin="dense"
            id="label1"
            label="Search..."
            type="text"
            fullWidth
            variant="outlined"
            InputProps={{
              className: classes.input,
            }}
            onChange={(e) => setSearch(e.target.value)}
          />
          <ReactSortable list={conditionList} setList={setConditionList}>
          {conditionList
            .filter((ii) =>
              search && ii.conditionName
                ? ii.conditionName
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
                : search && ii.conditionText
                ? ii.conditionText
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
                : ii
            )
            .map((condItem, inx) => {
              return (
                <div className={styles.selectItem} key={inx}>
                  
                  {
                    OpenField == inx ? <>
                    <TextField
                    value={conditionName}
                    margin="dense"
                    id="label1"
                    label="Condition Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      className: classes.margin,
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton onClick={()=> changeConditionName()}>
                            <Done />
                          </IconButton>
                          <IconButton onClick={()=>handleCloseField()}>
                            <CancelOutlined />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setConditionName(e.target.value)}
                    
                  /> 
                  <span>
                    {condItem.conditionText}
                  </span>
                  </>
                    
                    
                    : <>
                    <div style={{marginBottom:"10px"}}>
                    <CustomButton handle={()=>{ setConditionName(condItem.conditionName); handleOpenField(inx)}} label={"Add Description"}  />
                      
                    </div>
                    {/* <FormControlLabel
                    control={
                      <Checkbox
                      checked={OpenField == inx ? true : false }
                      onClick={() => {
                        handleOpenField(inx)
                      }}
                      />
                    }
                   
                  /> */}
                  <span onClick={() => conditionSelect(condItem)}>
                    {condItem.conditionName
                      ? condItem.conditionName
                      : condItem.conditionText}
                  </span></>
                  }

                  
                </div>
              );
            })}
          </ReactSortable>

          
        </div>
      </div>
    </Dialog>
  );
};


export default SelectCondition;
