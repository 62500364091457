import React from 'react'
import Button from "@mui/material/Button";
import styles from "../changeLabel/changeLabel.module.scss";
function Index({label,handle,active}) {
    return (
      <Button  variant="contained"
      onClick={handle}
    className={`${active ? styles.activeButton : styles.default}`}>{label}</Button>  
    )
}

export default Index
