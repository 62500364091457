import React, { useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TextField } from "@mui/material";
import styles from "./customSearch.module.scss";
import { useAppContext } from "../../shared";
import SearchIcon from "@mui/icons-material/Search";
const CustomSearch = ({
  handleOptionSelect,
  optionIndex,
  showAllNodes,
  selectElement,
  optionSubIndex,
}) => {
  const { elements, setElements } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const [tempElements, setTempElements] = useState(elements);
  const [search, setSearch] = useState("");
  const [selectedElement, setSelectedElement] = useState(selectElement || "");
  useEffect(() => {
    setSelectedElement(selectElement);
  }, [selectElement]);
  const handleSearch = (e) => {
    let temp = [...elements];
    temp = temp.filter((item, index) =>
      item?.data?.label?.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setTempElements(temp);
    setSearch(e.target.value);
  };
  const handleElementSelect = (item, index) => {
    setSelectedElement(item.data.label);
    setShowModal(!showModal);
    handleOptionSelect(item, optionIndex, optionSubIndex);
  };
  return (
    <>
      <div
        className={styles.container}
        onClick={() => setShowModal(!showModal)}
      >
        <div className={styles.selectElement}>
          <p className={styles.labelStyles}>
            {selectedElement || "Please select the Node"}
          </p>
        </div>
        <div className={styles.arrowDropDownContainer}>
          <ArrowDropDownIcon />
        </div>
      </div>
      {showModal && (
        <div className={styles.textFieldContainer}>
          <TextField
            value={search}
            margin="dense"
            id="label1"
            label="Search Nodes"
            type="text"
            fullWidth
            variant="standard"
            className={styles.minWidth}
            onChange={handleSearch}
            autoComplete="off"
          />
          {/* <SearchIcon className={styles.searchIconStyle} /> */}
          {tempElements?.length > 0 &&
            tempElements.map((item, index) => {
              if (
                (item?.data && item.data.isConditional) ||
                (item.data && showAllNodes)
              ) {
                return (
                  <div
                    className={styles.labelStyles}
                    onClick={() => handleElementSelect(item, index)}
                    key={index}
                  >
                    {item?.data?.label}
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      )}
      {showModal && tempElements.length > 0 ? (
        <div className={styles.horizontalLineContainer}></div>
      ) : null}
    </>
  );
};
export { CustomSearch };
