import { v4 as uuidv4 } from "uuid";
const computeDuplicateOption = ({
  conditionOptions,
  tobeEdited,
  summaryDesc,
  summaryDesc_ES,
  catagory,
  optionTarget,
  optionLabel,
  optionLabel_ES
}) => {
  let temp = [...conditionOptions];
  let prevSameOption = "";
  for (var j of tobeEdited.data.conditions) {
    for (var i of j.conditionOptions) {
      if (i.text === optionLabel) {
        prevSameOption = i;
        break;
      }
    }
    if (prevSameOption !== "") {
      break;
    }
  }
  if (prevSameOption !== "") {
    if (
      window.confirm(
        "This option already exists. Are you sure you want to use previous option?"
      )
    ) {
      // Save it!
      temp.push({
        id: prevSameOption.id,
        summaryDescription: summaryDesc,
        summaryDescription_ES:summaryDesc_ES,
        summaryCatagory: catagory,
        targetNode: optionTarget,
        text: optionLabel,
        text_ES:optionLabel_ES
      });
    } else {
      // Do nothing!
      temp.push({
        id: uuidv4(),
        summaryDescription: summaryDesc,
        summaryDescription_ES:summaryDesc_ES,
        summaryCatagory: catagory,
        targetNode: optionTarget,
        text: optionLabel,
        text_ES:optionLabel_ES
      });
    }
  } else {
    temp.push({
      id: uuidv4(),
      summaryDescription: summaryDesc,
      summaryDescription_ES:summaryDesc_ES,
      summaryCatagory: catagory,
      targetNode: optionTarget,
      text: optionLabel,
      text_ES:optionLabel_ES
    });
  }
  return temp;
};
export { computeDuplicateOption };
