import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./viewConditions.module.scss";
import { ReactSortable } from "react-sortablejs";
import {
  useAppContext,
  CustomSearch,
  CustomEditor,
  CustomSummary,
  CloseIcon,
  computeCondition,
  computeDuplicateOption,
  prepareEditCondition,
} from "../../shared";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "@mui/material";
import { convertToRaw, EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import draftToHtml from "draftjs-to-html";
import ActionLink from "../actionLink/actionLink";
import CustomButton from '../button'
import { makeStyles } from "@material-ui/core";
import { ConditionDetail } from '../../assets'
import SelectConditionModal from '../addCondition/selectConditionModal'
const useStyles = makeStyles(() => ({
  input: {
    borderRadius: 0,
  },
}));
const EditCondition = ({ open, handleClose, editCondition }) => {
  const classes = useStyles();
  const [conditions, setConditions] = useState([]);
  const [subConditions, setSubConditions] = useState([]);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [label_ES, setLabel_ES] = useState("");
  const [label, setLabel] = useState("");
  const [optionLabel, setOptionLabel] = useState("");
  const [optionLabel_ES, setOptionLabel_ES] = useState("");
  const [optionTarget, setOptionTarget] = useState("");
  const [selectOption, setSelectedOptions] = useState('')
  const [linkArr, setLinkArr] = useState([]);
  const [testArr, setTestArr] = useState([]);
  const [catagory, setCatagory] = useState("");
  const [summaryDesc, setSummaryDesc] = useState("");
  const [summaryCheck, setSummaryCheck] = useState(false);
  const [scheduleBool, setScheduleBool] = useState(false)
  const [immigrationBool, setimmigrationBool] = useState(false);
  const [conditionName, setConditionName] = useState("");
  const [selectConditionModal, setSelectConditionalModal] = useState(false)
  const [modalres, setModal] = useState(false);
  const [spanisCheck, setSpanishCheck] = useState(false);
  const [editorState, setContentState] = useState(EditorState.createEmpty());
  const [editorSpanishState, setSpanishContentState] = useState(EditorState.createEmpty());
  const [contentStateSummary, setContentStateSummary] = useState(EditorState.createEmpty());
  const [SpanishContentStateSummary, setSpanishContentStateSummary] = useState(EditorState.createEmpty());

  const [selectedElement, setSelectedElement] = useState("");
  const [edit, setEdit] = useState(false);
  const [tobeeditedOption, setTobeeditedOption] = useState("");

  const { elements, setElements, tobeEdited, setTobeEdited, setUpdateIndicator, addConditionInList } = useAppContext();

  const getNodeLabel = (nodeId) => {
    let tempElement = elements.filter((item, index) => item.id === nodeId);

    return tempElement[0]?.data?.label;
  };
  const getOptionLabel = (nodeId, optionId) => {
    let tempElement = elements.filter((item, index) => item.id === nodeId);
    let tempOption = tempElement[0]?.data?.child?.filter(
      (item, index) => item.id === optionId
    );
    return tempOption[0]?.text;
  };
  const getElement = (id) => {
    return elements.filter((item, index) => item.id === id);
  };
  const getOptions = (tempElement, id) => {
    let options = [];
    tempElement[0].data.conditions.forEach((item, index) => {
      if (item.id !== id) {
        options = [...options, ...item.conditionOptions];
      }
    });
    return options;
  };

  useEffect(() => {

    setConditions([]);
    setConditionOptions([]);
    let condition = editCondition.condition;
    setConditionOptions(editCondition.conditionOptions);
    setConditionName(editCondition?.conditionName ? editCondition?.conditionName : "")
    setScheduleBool(editCondition?.scheduleBool ? editCondition?.scheduleBool : false);
    setimmigrationBool(editCondition?.immigrationBool ? editCondition?.immigrationBool : false)
    var contentState = stateFromHTML(editCondition.conditionDescription?.replace(/&lt;/ig, '<'));
    contentState = EditorState.createWithContent(contentState);
    var SpanishcontentState = stateFromHTML(editCondition?.conditionDescription_ES?.replace(/&lt;/ig, '<') ? editCondition?.conditionDescription_ES?.replace(/&lt;/ig, '<') : "");
    SpanishcontentState = EditorState.createWithContent(SpanishcontentState);
    setSpanishContentState(SpanishcontentState)
    setContentState(contentState);
    setLabel(editCondition.conditionLabel);
    setLabel_ES(editCondition?.conditionLabel_ES ? editCondition?.conditionLabel_ES : "");
    setLinkArr(editCondition?.actionLink ? editCondition?.actionLink : [])


    let temp = prepareEditCondition({
      condition: condition,
      elements: elements,
      editCondition: editCondition,
    });

    setConditions(temp);
  }, [editCondition]);

  const handleNodeSelect = (item, optionIndex) => {
    let temp = [...conditions];
    temp[optionIndex].nodeId = item.id;
    temp[optionIndex].nodeLabel = item.data.label;
    let optionsInCondition = [...item.data.child];
    item?.data?.conditions?.forEach((item, index) => {
      optionsInCondition = [...optionsInCondition, ...item.conditionOptions];
    });
    let uniqueOptions = [];
    optionsInCondition.forEach((item, index) => {
      let find = uniqueOptions.find(
        (newItem, newIndex) => newItem.id === item.id
      );
      if (!find) {
        uniqueOptions.push(item);
      }
    });
    temp[optionIndex].options = uniqueOptions;
    setConditions(temp);
  };
  const toggleOption = (index) => {
    let temp = [...conditions];
    temp[index].showOption = !temp[index].showOption;
    setConditions(temp);
  };
  const handleNodeOptionSelect = (e, index) => {
    let temp = [...conditions];
    // let getOption = temp[index]?.options?.find(i=>i?.id == e.target.value)
    temp[index].optionId = e.target.value;
    // temp[index].optionLabel = getOption.text;
    setConditions(temp);
  };
  const hanldeAndOr = (e, index) => {
    let temp = [...conditions];
    temp[index].andOr = e.target.value;
    setConditions(temp);
  };
  const handleDeleteOption = (item) => {
    setUpdateIndicator(true)
    let newTemp = [...elements];
    let index = elements.findIndex(
      (newItem, index) =>
        newItem.source === tobeEdited.id && newItem.target === item.targetNode
    );
    newTemp.splice(index, 1);

    let temp = [...conditionOptions];
    temp = temp.filter((newItem, index) => newItem.id !== item.id);
    setConditionOptions(temp);
    setElements(newTemp);
  };
  const onEditorStateChange = (editor) => {
    setContentState(editor);
  };
  const onSpanishStateChange = (editor) => {
    setSpanishContentState(editor);
  };
  const onStateChangeSummary = (editor) => {
    setContentStateSummary(editor);
  };
  const onStateChangeSpanishSummary = (editor) => {
    setSpanishContentStateSummary(editor);
  };
  const handleAddCondition = () => {
    let temp = [...conditions];
    temp.push({
      id: uuidv4(),
      nodeId: "",
      nodeLabel: "",
      optionId: "",
      optionLabel: "",
      andOr: "",
      showOption: false,
      showAddOr: false,
      options: [],
      extraNodes: [],
    });
    setConditions(temp);
  };

  const handleAddModal = () => {
    setModal(!modalres);
  }

  const handleRemoveCondition = (item, index) => {
    let temp = [...conditions];
    temp = temp.filter((newItem, newIndex) => newItem.id !== item.id);
    setConditions(temp);
  };
  const handleEditOption = (item, index) => {

    setTobeeditedOption(item);
    setOptionLabel(item.text);
    setOptionLabel_ES(item?.text_ES || "")
    var contentStateSummary = stateFromHTML(item.summaryDescription?.replace(/&lt;/ig, '<') ? item.summaryDescription?.replace(/&lt;/ig, '<') : item.summaryDescription || "");
    var contentSpanishStateSummary = stateFromHTML(item.summaryDescription_ES?.replace(/&lt;/ig, '<') ? item.summaryDescription_ES?.replace(/&lt;/ig, '<') : item.summaryDescription_ES || "");
    contentStateSummary = EditorState.createWithContent(contentStateSummary);
    contentSpanishStateSummary = EditorState.createWithContent(contentSpanishStateSummary);
    setContentStateSummary(contentStateSummary)
    setSpanishContentStateSummary(contentSpanishStateSummary)
    setCatagory(item.summaryCatagory);
    // setSummaryDesc(item.summaryDescription);
    setOptionTarget(item.targetNode);
    setSelectedOptions(item.targetNode)
    let temp = elements.filter(
      (newItem, index) => newItem.id === item.targetNode
    );

    if (temp && temp.length > 0) {
      setSelectedElement(temp[0].data.label);

    } else {
      setSelectedElement("");
    }

    setEdit(true);
  };
  const AddOptionInCondition = () => {
    setUpdateIndicator(true)
    let temp = computeDuplicateOption({
      conditionOptions: conditionOptions,
      tobeEdited: tobeEdited,
      summaryDesc: draftToHtml(convertToRaw(contentStateSummary.getCurrentContent())),
      summaryDesc_ES: draftToHtml(convertToRaw(SpanishContentStateSummary.getCurrentContent())),
      catagory: catagory,
      optionTarget: optionTarget,
      optionLabel: optionLabel,
      optionLabel_ES: optionLabel_ES
    });
    setConditionOptions(temp);

    temp = [...elements];
    temp.push({
      id: uuidv4(),
      source: tobeEdited.id,
      target: optionTarget,
      animated: false,
      sourceHandle: "a",
      targetHandle: null,
      arrowHeadType: "arrowclosed",
    });
    setElements(temp);
    setSummaryDesc("");
    setCatagory("");
    setOptionTarget("");
    setOptionLabel("");
    setOptionLabel_ES("");
    setContentStateSummary(EditorState.createEmpty());
    setSpanishContentStateSummary(EditorState.createEmpty());
  };
  const EditOptionInCondition = () => {
    setUpdateIndicator(true)
    let temp = [...conditionOptions];
    let cloneAllElements = [...elements];
    let index = conditionOptions.findIndex(
      (item, index) => item.id === tobeeditedOption.id
    );

    temp[index].summaryDescription = draftToHtml(
      convertToRaw(contentStateSummary.getCurrentContent())
    );

    temp[index].summaryDescription_ES = draftToHtml(
      convertToRaw(SpanishContentStateSummary.getCurrentContent())
    );

    temp[index].summaryCatagory = catagory;
    temp[index].targetNode = optionTarget;
    temp[index].text = optionLabel;
    temp[index].text_ES = optionLabel_ES;

    let checkExistNodeLink = cloneAllElements.findIndex(i => i.target == selectOption && i.source == tobeEdited.id)

    let obj = {}
    if (optionTarget != null) {
      if (checkExistNodeLink < 0) {
        obj = {
          id: uuidv4(),
          source: tobeEdited.id,
          target: optionTarget,
          animated: false,
          sourceHandle: "a",
          targetHandle: null,
          arrowHeadType: "arrowclosed",
        }
        cloneAllElements.push(obj)
      } else {

        cloneAllElements[checkExistNodeLink] = {
          ...cloneAllElements[checkExistNodeLink],
          source: tobeEdited.id,
          target: optionTarget,

        }

      }
    }

    setEdit(!edit);
    setSelectedOptions("")
    setElements(cloneAllElements);
    setConditionOptions(temp);

    setTobeeditedOption("");
    setOptionLabel("");
    setOptionLabel_ES("")
    setCatagory("");
    setSummaryDesc("");
    setOptionTarget("");
    setSelectedElement("");
    setContentStateSummary(EditorState.createEmpty());
    setSpanishContentStateSummary(EditorState.createEmpty());
  };
  const handleEditAddOr = (index) => {
    let temp = [...conditions];
    temp[index].showAddOr = !temp[index].showAddOr;
    setConditions(temp);
  };
  const toggleSubOption = (optionIndex, subOptionIndex) => {
    let temp = [...conditions];
    temp[optionIndex].extraNodes[subOptionIndex].showOption =
      !temp[optionIndex].extraNodes[subOptionIndex].showOption;
    setConditions(temp);
  };
  const handleSubOptionSelect = (item, optionIndex, optionSubIndex) => {
    let temp = [...conditions];
    let optionsInCondition = [];
    item?.data?.conditions?.forEach((newItem, newIndex) => {
      optionsInCondition = [...optionsInCondition, ...newItem.conditionOptions];
    });
    let uniqueOptions = [];
    optionsInCondition.forEach((newItem, newIndex) => {
      let find = uniqueOptions.find(
        (newItem1, newIndex2) => newItem.id === newItem1.id
      );
      if (!find) {
        uniqueOptions.push(newItem);
      }
    });
    temp[optionIndex].extraNodes[optionSubIndex].nodeId = item.id;
    temp[optionIndex].extraNodes[optionSubIndex].nodeLabel = item.data.label;
    temp[optionIndex].extraNodes[optionSubIndex].options = [
      ...item.data.child,
      ...uniqueOptions,
    ];

    setConditions(temp);
  };
  const handleSubNodeOptionSelect = (e, optionIndex, index) => {
    let temp = [...conditions];
    temp[optionIndex].extraNodes[index].optionId = e.target.value;
    setConditions(temp);
  };
  const handleAddSubCondition = (optionIndex) => {
    let temp = [...conditions];
    temp[optionIndex].extraNodes.push({
      id: uuidv4(),
      nodeId: "",
      optionId: "",
      nodeLabel: "",
      options: [],
      showOption: false,
    });
    setConditions(temp);
  };
  const handleRemoveSubOption = (optionIndex, index) => {
    let temp = [...conditions[optionIndex].extraNodes];
    temp.splice(index, 1);
    let newTemp = [...conditions];
    newTemp[optionIndex].extraNodes = temp;
    setConditions(newTemp);
  };
  const onSubmit = () => {

    var finalCondition = computeCondition(conditions);
    let temp = elements.filter((item, index) => item.id === tobeEdited.id);
    let index = temp[0]?.data?.conditions?.findIndex(
      (item, index) => item.id === editCondition.id
    );
    let tempCondition = temp[0]?.data?.conditions[index];
    tempCondition.condition = finalCondition;
    tempCondition.conditionDescription = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    tempCondition.conditionDescription_ES = draftToHtml(
      convertToRaw(editorSpanishState.getCurrentContent())
    );

    tempCondition.conditionLabel = label;
    // tempCondition["conditionName"] = conditionName;
    tempCondition.conditionLabel_ES = label_ES;
    tempCondition.conditionOptions = conditionOptions;
    if (tempCondition?.actionLink) {
      tempCondition.actionLink = linkArr
    } else {
      tempCondition["actionLink"] = linkArr
    }
    // if (tempCondition?.immigrationBool) {
    //   tempCondition.immigrationBool = immigrationBool
    // } else {
    //   tempCondition["immigrationBool"] = immigrationBool
    // }
    addConditionInList(tempCondition)
    temp[0].data.conditions[index] = tempCondition;
    let elementIndex = elements.findIndex(
      (item, index) => item.id === tobeEdited.id
    );
    let temp2 = [...elements];
    temp2[elementIndex] = temp[0];
    setTobeEdited(temp[0]);
    setElements(temp2);
    handleClose();
  };
  const handleConditionClose = () => setSelectConditionalModal(false)
  const openConditionModal = () => setSelectConditionalModal(true)
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            minHeight: "600px",
            maxHeight: "600px",
            backgroundColor: "#fffaed",
          },
        }}
      >
        <div className={styles.iconContainer}>
          <CloseIcon handleClose={handleClose} />
        </div>
        <div className={styles.conditionContainer}>
          {/* <div className={styles.margin}>
        <InputLabel id="label1" className={styles.labelStyles}>
          Condition Name:
        </InputLabel>
        <div className={styles.nickName}>
          <TextField
            value={conditionName}
            margin="dense"
            id="label1"
            label="Condition Name"
            type="text"
            fullWidth
            variant="outlined"
            InputProps={{
              className: classes.input,
            }}
            onChange={(e) => setConditionName(e.target.value)}
          />
          <Button onClick={openConditionModal} className={styles.nickButton}>
            <img src={ConditionDetail} width="35px" />
            
          </Button>
        </div>
      </div> */}
          {conditions?.length > 0 &&
            conditions?.map((item, index) => {
              return (
                <div key={index}>
                  <div className={`${styles.customSearchContainer} ${styles.nickName}`}>
                    <div className={`${index == 0 ? styles.w_89 : styles.w_100}`}>
                      <CustomSearch
                        selectElement={item.nodeLabel}
                        optionIndex={index}
                        handleOptionSelect={handleNodeSelect}
                      />
                    </div>
                    {index == 0 ? (
                      <Button
                        onClick={openConditionModal}
                        className={styles.nickButton}
                      >
                        <img src={ConditionDetail} width="35px" />
                      </Button>
                    ) : (
                      ""
                    )}

                  </div>
                  <div className={styles.selectCheckBox}>
                    <div className={styles.flex1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item?.showOption}
                            onClick={() => {
                              toggleOption(index);
                            }}
                          />
                        }
                        label="Select if you want to add check on option as well"
                      />
                    </div>
                    <div className={styles.removeIconContainer}>
                      <RemoveIcon
                        className={styles.removeIconStyles}
                        onClick={() => handleRemoveCondition(item, index)}
                      />
                    </div>
                  </div>
                  {item.showOption ? (
                    <FormControl fullWidth className={styles.conditionMargin}>
                      <InputLabel id="select-option-select">
                        Select Option
                      </InputLabel>
                      <Select
                        labelId="select-option-select-label"
                        id="select-option-select"
                        value={item.optionId}
                        label="Select Option"

                        onChange={(e) => handleNodeOptionSelect(e, index)}
                      >
                        <MenuItem value={""} >
                          Select Option
                        </MenuItem>
                        {item.options?.map((newItem, newIndex) => {
                          return (
                            <MenuItem value={newItem.id} key={newIndex}>
                              {newItem?.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : null}
                  <div className={styles.nextStylesContainer}>
                    {
                      console.log('newItem', item)
                    }
                    {
                      item?.extraNodes?.length > 0 ?
                        <Button
                          variant="contained"
                          className={styles.nextStyles}
                          onClick={() => handleEditAddOr(index)}
                        >
                          Edit Or
                        </Button> : <Button
                          variant="contained"
                          className={styles.defaultOr}
                          onClick={() => handleEditAddOr(index)}
                        >
                          Add Or
                        </Button>
                    }


                  </div>
                  {index !== conditions.length - 1 ? (
                    <FormControl fullWidth className={styles.conditionMargin}>
                      <InputLabel id="select-binary-simple-select">
                        Select Condition
                      </InputLabel>
                      <Select
                        labelId="simple-binary-select-label"
                        id="select-binary-simple-select"
                        value={item.andOr}
                        label="Select Condition"
                        onChange={(e) => hanldeAndOr(e, index)}
                      >
                        <MenuItem value="AND NOT">AND NOT</MenuItem>
                        <MenuItem value="AND">AND</MenuItem>
                        <MenuItem value="OR">OR</MenuItem>
                      </Select>
                    </FormControl>
                  ) : null}
                  <AddOrModal
                    modal={item.showAddOr}
                    handleClose={() => handleEditAddOr(index)}
                    handleSubOptionSelect={handleSubOptionSelect}
                    optionIndex={index}
                    extraNodes={item.extraNodes}
                    handleAddSubCondition={handleAddSubCondition}
                    toggleSubOption={toggleSubOption}
                    handleSubNodeOptionSelect={handleSubNodeOptionSelect}
                    handleRemoveSubOption={handleRemoveSubOption}
                  />
                </div>
              );
            })}
          <div className={styles.nextStylesContainer}>
            <Button
              variant="contained"
              className={styles.nextStyles}
              onClick={handleAddCondition}
            >
              Add If
            </Button>
          </div>
          <div>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={spanisCheck}
                  onClick={() => setSpanishCheck(!spanisCheck)}
                />
              }
              label="Spanish"
            /> */}
            <div className={styles.margin}>

              <CustomButton handle={() => { setSpanishCheck(!spanisCheck); }} label={"Edit Spanish Language"} active={spanisCheck} />
            </div>
            <div className={styles.margin}>
              <InputLabel id="label1" className={styles.labelStyles}>
                Enter Label:
              </InputLabel>
              <TextField
                value={label}
                margin="dense"
                id="label1"
                label="Enter Label"
                type="text"
                fullWidth
                variant="outlined"
                className={styles.minWidth}
                onChange={(e) => setLabel(e.target.value)}
              />
              {
                spanisCheck && <>
                  <InputLabel id="label1" className={styles.labelStyles}>
                    Introducir etiqueta:
                  </InputLabel>
                  <TextField
                    value={label_ES}
                    margin="dense"
                    id="label1"
                    label="Introducir etiqueta"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className={styles.minWidth}
                    onChange={(e) => setLabel_ES(e.target.value)}
                  />
                </>
              }
            </div>
          </div>

          <div>
            <div className={styles.margin}>
              <InputLabel id="label1" className={styles.labelStyles}>
                Enter Description:
              </InputLabel>
            </div>
            <CustomEditor
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
            />
            {
              spanisCheck && <>
                <div className={styles.margin}>
                  <InputLabel id="label1" className={styles.labelStyles}>
                    Introduzca la descripción:
                  </InputLabel>
                </div>
                <CustomEditor
                  editorState={editorSpanishState}
                  onEditorStateChange={onSpanishStateChange}

                />

              </>
            }
            {/* <div className={styles.defaultMarginStyles}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={scheduleBool}
                  onClick={() => {
                    setScheduleBool(!scheduleBool);
                  }}
                />
              }
              label="Select if you want schedule a consultation"
            />
          </div>
          <div className={styles.defaultMarginStyles}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={immigrationBool}
                  onClick={() => {
                    setimmigrationBool(!immigrationBool);
                  }}
                />
              }
              label="Select if you want to begin a immigration process"
            />
          </div> */}
            <div className={styles.nextContainer}>
              <Button
                variant="contained"
                className={`${styles.nextStyles} ${styles.actionbutton}`}
                onClick={handleAddModal}
              >
                Action Link
              </Button>
            </div>
          </div>
          <div className={styles.optionContainer}>
            <ReactSortable list={conditionOptions} setList={setConditionOptions} axis={'y'} >
              {conditionOptions?.length > 0 &&
                conditionOptions?.map((item, index) => {
                  return (
                    <div key={index} className={styles.optionInnerContainer}>
                      <div className={styles.optionTextStyles}>
                        <p>{item.text}</p>
                      </div>
                      <div className={styles.iconSubContainer}>
                        <div
                          className={styles.deleteIconContainer}
                          onClick={() => handleDeleteOption(item)}
                        >
                          <DeleteIcon />
                        </div>
                        <div onClick={() => handleEditOption(item, index)}>
                          <EditIcon />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </ReactSortable>
          </div>
          <div className={styles.margin}>
            <InputLabel id="label3" className={styles.labelStyles}>
              Enter Option Title:
            </InputLabel>
            <TextField
              value={optionLabel}
              margin="dense"
              id="label3"
              label="Option Title"
              type="text"
              fullWidth
              variant="outlined"
              className={styles.minWidth}
              onChange={(e) => setOptionLabel(e.target.value)}
            />
            {
              spanisCheck && <>
                <InputLabel id="label3" className={styles.labelStyles}>
                  Ingrese el título de la opción:
                </InputLabel>
                <TextField
                  value={optionLabel_ES}
                  margin="dense"
                  id="label3"
                  label="Título de la opción"
                  type="text"
                  fullWidth
                  variant="outlined"
                  className={styles.minWidth}
                  onChange={(e) => setOptionLabel_ES(e.target.value)}
                /></>
            }
            {edit ? (
              <CustomSearch
                handleOptionSelect={(item, optionIndex) => {
                  setOptionTarget(item.id);
                }}
                selectElement={selectedElement}
                optionIndex="dummy value"
                showAllNodes={true}
              />
            ) : (
              <CustomSearch
                handleOptionSelect={(item, optionIndex) => {
                  setOptionTarget(item.id);
                }}
                selectElement={selectedElement}
                optionIndex="dummy value"
                showAllNodes={true}
              />
            )}
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={summaryCheck}
                  onClick={() => {
                    setSummaryCheck(!summaryCheck);
                  }}
                />
              }
              label="Check if you want to add summary"
            /> */}
            <div className={styles.margin}>

              <CustomButton handle={() => { setSummaryCheck(!summaryCheck); }} label={"Add text to View Your Evaluation"} active={summaryCheck} />
            </div>
            {summaryCheck ? (
              <>
                <CustomSummary
                  catagory={catagory}
                  setCatagory={setCatagory}
                  summaryDesc={contentStateSummary}
                  categoryBool={true}
                  onEditorStateChange={onStateChangeSummary}
                  placeholderText={"Enter Summary Description"}
                  title={"Enter Summary Description:"}
                />
                {spanisCheck && (
                  <div style={{ marginTop: '10px' }}>
                    <CustomSummary
                      catagory={catagory}
                      setCatagory={setCatagory}
                      summaryDesc={SpanishContentStateSummary}
                      categoryBool={false}
                      onEditorStateChange={onStateChangeSpanishSummary}
                      placeholderText={"Ingrese la descripción resumida"}
                      title={"Ingrese la descripción resumida:"}
                    />
                  </div>
                )}

              </>
            ) : null}
          </div>
          <div>
            {edit ? (
              <Button
                variant="contained"
                className={styles.nextStyles}
                onClick={EditOptionInCondition}
              >
                Edit Option
              </Button>
            ) : (
              <Button
                variant="contained"
                className={styles.nextStyles}
                onClick={AddOptionInCondition}
              >
                Add Option
              </Button>
            )}
          </div>
          <div className={styles.editConditionContainer}>
            <Button
              variant="contained"
              className={styles.nextStyles}
              onClick={onSubmit}
            >
              Edit Condition
            </Button>
          </div>
        </div>
      </Dialog>
      <SelectConditionModal condition={conditions} setCondition={setConditions} modal={selectConditionModal} handleClose={handleConditionClose} />
      <ActionLink
        modal={modalres}
        linkArr={linkArr}
        setLinkArr={setLinkArr}
        handleClose={handleAddModal}
      />
    </>
  );
};

const AddOrModal = ({
  modal,
  handleClose,
  handleSubOptionSelect,
  optionIndex,
  extraNodes,
  handleAddSubCondition,
  toggleSubOption,
  handleSubNodeOptionSelect,
  handleRemoveSubOption,
}) => {
  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          minHeight: "600px",
          maxHeight: "600px",
          backgroundColor: "#fffaed",
        },
      }}
    >
      <div style={{ margin: "10px 10px" }}>
        <div>
          <CloseIcon handleClose={handleClose} />
        </div>
        {extraNodes?.map((item, index) => {
          return (
            <div style={{ margin: "10px 0px" }} key={index}>
              <div>
                <CustomSearch
                  handleOptionSelect={handleSubOptionSelect}
                  optionIndex={optionIndex}
                  optionSubIndex={index}
                  selectElement={item.nodeLabel}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item?.showOption}
                        onClick={() => {
                          toggleSubOption(optionIndex, index);
                        }}
                      />
                    }
                    label="Select if you want to add check on option as well"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <RemoveIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveSubOption(optionIndex, index)}
                  />
                </div>
              </div>
              <div>
                {item?.showOption ? (
                  <div>
                    <FormControl fullWidth className={styles.conditionMargin}>
                      <InputLabel id="select-option-select">
                        Select Option
                      </InputLabel>
                      <Select
                        labelId="select-option-select-label"
                        id="select-option-select"
                        value={item.optionId}
                        label="Select Option"
                        onChange={(e) =>
                          handleSubNodeOptionSelect(e, optionIndex, index)
                        }
                      >
                        {item.options?.map((newItem, newIndex) => {
                          return (
                            <MenuItem value={newItem.id} key={newIndex}>
                              {newItem?.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
        <div className={styles.orContainer}>
          <Button
            variant="contained"
            className={styles.nextStyles}
            onClick={() => handleAddSubCondition(optionIndex)}
          >
            Add another Node
          </Button>
        </div>
      </div>

    </Dialog>
  );
};
export default EditCondition;
