import React from "react";
import Dialog from "@mui/material/Dialog";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import LinkNode from "../linkNode/linkNode";
import AddCondition from "../addCondition/addCondition";
import AddSummary from "../addSummary/addSummary";
import ViewConditions from "../viewConditions/viewConditions";
import AddMultiCondition from "../addMultiCondition/addMultiCondition";
import styles from "./editNode.module.scss";
import { useEffect } from "react";
import { NODE_TYPE } from "../../shared/utils/constants";
import ChangeLabel from "../changeLabel/changeLabel";
import { CloseIcon } from "../../shared";

const EditNode = ({
  modal,
  handleClose,
  tobeEdited,
  setTobeEdited,
  handleEdit,
  elements,
  setElements,
  handleAddModal,
  nodeSummary,
  setNodeSummary,
  setUpdateIndicator
}) => {
  const [value, setValue] = React.useState(0);
  const [disableMultiCondition, setMultiCondition] = React.useState(true);
  useEffect(() => {
    if (tobeEdited?.data?.type === NODE_TYPE.multiLink) {
      setMultiCondition(false);
    } else {
      setMultiCondition(true);
    }
  }, [elements]);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const enableMultiCondition = () => {
    setMultiCondition(false);
  };

  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          minHeight: "600px",
          maxHeight: "600px",
          backgroundColor: "#fffaed",
        },
      }}
    >
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="basic tabs example"
        className={styles.container}
      >
        <Tab label="Change Node" {...0} />
        <Tab label="Unconditional Links" {...a11yProps(1)} />
        <Tab label="Add Condition" {...a11yProps(2)} />
        {/* <Tab label="Add Summary" {...a11yProps(2)} /> */}
        <Tab label="Conditional Text/Links" {...a11yProps(3)} />
        {/* <Tab label="Add Multi Condition" {...a11yProps(4)} /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <ChangeLabel handleClose={handleClose} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LinkNode
          tobeEdited={tobeEdited}
          setTobeEdited={setTobeEdited}
          handleEdit={handleEdit}
          elements={elements}
          handleClose={handleClose}
          setElements={setElements}
          handleAddModal={handleAddModal}
          enableMultiCondition={enableMultiCondition}
          setUpdateIndicator={setUpdateIndicator}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AddCondition
          tobeEdited={tobeEdited}
          handleEdit={handleEdit}
          elements={elements}
          handleClose={handleClose}
          setElements={setElements}
          setUpdateIndicator={setUpdateIndicator}
        />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <AddSummary
          tobeEdited={tobeEdited}
          handleEdit={handleEdit}
          elements={elements}
          handleClose={handleClose}
          setElements={setElements}
          nodeSummary={nodeSummary}
          setNodeSummary={setNodeSummary}
        />
      </TabPanel> */}
      <TabPanel value={value} index={3}>
        <ViewConditions
          tobeEdited={tobeEdited}
          elements={elements}
          setElements={setElements}
          handleClose={handleClose}
        />
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        <AddMultiCondition tobeEdited={tobeEdited} handleClose={handleClose} />
      </TabPanel> */}
      <div className={styles.closeIconContainer}>
        <CloseIcon handleClose={handleClose} />
      </div>
    </Dialog>
  );
};

export default EditNode;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={styles.container}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
