import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useAppContext,
  ConfirmationDialog,
  NODE_TYPE,
  prepareViewCondition,
} from "../../shared";
import { toast } from "react-toastify";
import styles from "./viewConditions.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import EditCondition from "./editCondition";
import { ReactSortable } from "react-sortablejs";


const ViewConditions = ({ elements, setElements, handleClose }) => {
  const { tobeEdited, setTobeEdited } = useAppContext();
  const [condition, setCondition] = useState([]);
  const [open, setOpen] = useState(false);
  const [dataItem, setItem] = useState("");
  const [nodeType, setType] = useState(NODE_TYPE.link);
  const [conditionArr, setConditionArr] = useState([])
  const [editCondition, setEditCondition] = useState("");
  const [editModal, setEditModal] = useState(false);
  const labelParser = (nodeId) => {
    if (nodeId !== "AND" && nodeId !== "AND NOT" && nodeId !== "OR") {
      if (nodeId.includes("&&")) {
        //split nodeId and option Id
        let temp = nodeId.split("&&");
        let finalString = "";
        let index = elements?.findIndex((item, index) => item.id === temp[0]);
        finalString = elements[index]?.data?.label;
        //compute all options options
        let tempChild = elements[index]?.data?.child;
        if(index > -1){
          elements[index]?.data?.conditions.forEach((item, index) => {
            tempChild = [...tempChild, ...item.conditionOptions];
          });
        }
        
        let filteredChild = tempChild?.filter(
          (item, index) => item.id === temp[1]
        );
        if (filteredChild?.length > 0) {
          finalString = finalString + `&& ${filteredChild[0].text}`;
          return finalString;
        } else {
          return finalString;
        }
      } else {
        let index = elements?.findIndex((item, index) => item.id === nodeId);
        return elements[index]?.data?.label;
      }
    } else {
      return `${nodeId}`;
    }
  };
  const getLabel = (nodeId) => {
    if (nodeId.includes("||")) {
      let temp = nodeId.split("||");
      let finalString = "";
      temp.forEach((item, index) => {
        let tempString = getLabel(item);
        if (index + 1 === temp.length) {
          finalString = finalString + tempString;
        } else {
          finalString = finalString + tempString + " || ";
        }
      });
      return finalString;
    } else {
      return labelParser(nodeId);
    }
  };
  useEffect(() => {
    setCondition([]);
    let temp = prepareViewCondition({ tobeEdited: tobeEdited });

    setConditionArr(temp.data.conditions);
    setCondition(temp);
  }, [tobeEdited, elements]);

  const handleRemoveCondition = (item) => {
    let conditionIndex = tobeEdited.data.conditions.findIndex(
      (newItem) => newItem.id === item.id
    );
    let newObj = JSON.parse(JSON.stringify(tobeEdited));
    let temp = [...elements];
    if (conditionIndex > -1) {
      let node = newObj;
      let condOption = newObj.data.conditions[conditionIndex]?.conditionOptions
      console.log(node, 'condOption', condOption);
      if (condOption && condOption.length) {
        console.log('temp', temp);
        for (let index = 0; index < condOption.length; index++) {
          const element = condOption[index];
          let linkIndx = temp.findIndex((ii) => (ii?.source == node.id && ii?.target == element.targetNode))
          console.log(linkIndx, 'element', element);
          if (linkIndx > -1) {

            temp.splice(linkIndx, 1)
          }

        }
      }
    }
    newObj.data.conditions.splice(conditionIndex, 1);
    let index = elements.findIndex((item, index) => item.id === tobeEdited.id);
    // let temp = [...elements];
    temp[index] = newObj;
    setElements(temp);
    setTobeEdited(newObj);
    toast.success("You have successfully deleted the condition!", { theme: "colored", hideProgressBar: true });
  };
  const handleRemoveMultiCondition = (item) => {
    let conditionIndex = tobeEdited.data.optionCondition.findIndex(
      (newItem) => newItem.id === item.id
    );
    let newObj = JSON.parse(JSON.stringify(tobeEdited));
    newObj.data.optionCondition.splice(conditionIndex, 1);
    let index = elements.findIndex((item, index) => item.id === tobeEdited.id);
    let temp = [...elements];
    temp[index] = newObj;
    setElements(temp);
    setTobeEdited(newObj);
    toast.success("You have successfully deleted the condition!", { theme: "colored", hideProgressBar: true });
  };
  const handleCloseToggle = () => {
    setOpen(!open);
  };
  const handleYes = () => {
    console.log('item', dataItem);
    if (NODE_TYPE.multiLink === nodeType) {
      handleRemoveMultiCondition(dataItem);
    } else {
      handleRemoveCondition(dataItem);
    }
    setOpen(!open);
  };
  const setDeleteItem = (item, type) => {
    setItem(item);
    setType(type);
    setOpen(!open);
  };
  const handleEditCondition = (item, nodeType) => {
    console.log('handleEditCondition', item);
    setEditCondition(item);
    setEditModal(!editModal);
  };
  const handleCloseEdit = () => {
    setEditModal(!editModal);
  };


  const setConditions = (e) => {
    let temp = { ...tobeEdited };
    let newArr =  e.map((item)=>{
      if(typeof item.condition !== "string"){
        
        if(item.condition.length){
          let finalCondition = ''
          for (let index = 0; index < item.condition.length; index++) {
            const condItem = item.condition[index];
            if(condItem == "AND" || condItem == "AND NOT" || condItem == "OR"){
              finalCondition += '>'+condItem+">"
            }else{
              finalCondition +=condItem
            }
          }
          item.condition = finalCondition
        }
        
        return item
      }else{
        return item
      }
    })

    if(newArr?.length){
      temp['data']['conditions'] = newArr
      let verifyCondition = prepareViewCondition({ tobeEdited: temp })
      setConditionArr(verifyCondition.data.conditions)
      setTobeEdited(temp)
    }
  }



  return (
    <div >
      <div className={styles.closeIconContainer}>
        {/* <CloseIcon handleClose={handleClose} /> */}
      </div>
      <div
        style={{
          border:
            condition?.data?.conditions.length > 0 ? "1px solid black" : null,
          padding: "5px 5px",
        }}
      >
        <ReactSortable list={conditionArr} setList={(e) => setConditions(e)}>
          {conditionArr?.map((item, index) => {
            return (
              <div key={index} className={styles.contColr}>
                <div className={styles.deleteIconContainer}>
                  <div
                    className={styles.deleteStyles}
                    onClick={() => setDeleteItem(item, NODE_TYPE.link)}
                  >
                    <DeleteIcon />
                  </div>
                  <div
                    className={styles.deleteStyles}
                    onClick={() => handleEditCondition(item, NODE_TYPE.link)}
                  >
                    <EditIcon />
                  </div>
                </div>
                <p className={styles.descriptionHeader}>Condition:</p>
                {item?.condition?.map((newItem, newIndex) => {
                  return <div key={newIndex}>{getLabel(newItem)}</div>;
                })}
                <div>
                  <p className={styles.descriptionHeader}>Description:</p>
                  {/* <p>{item.conditionLabel}</p> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.conditionDescription?.replace(/&lt;/ig, '<'),
                    }}
                  />
                  {/* <p>{item.conditionDescription}</p> */}
                </div>
                <hr />
              </div>
            );
          })}
        </ReactSortable>
        {condition.data?.optionCondition?.map((item, index) => {
          return (
            <div key={index}>
              <div className={styles.deleteIconContainer}>
                <div
                  className={styles.deleteStyles}
                  onClick={() => setDeleteItem(item, NODE_TYPE.multiLink)}
                >
                  <DeleteIcon />
                </div>
              </div>
              {item?.condition?.map((newItem, newIndex) => {
                return <div key={newIndex}>{getLabel(newItem)}</div>;
              })}
              <div>
                <p>{item.label}</p>
                <p>{item.description}</p>
              </div>
              <hr />
            </div>
          );
        })}
        <ConfirmationDialog
          open={open}
          handleClose={handleCloseToggle}
          handleYes={handleYes}
        />
        <EditCondition
          open={editModal}
          handleClose={handleCloseEdit}
          editCondition={editCondition}
        />
      </div>
    </div>
  );
};
export default ViewConditions;
