import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import styles from "./ifConditional.module.scss";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { CustomSearch } from "../customSearch/customSearch";
import { CloseIcon } from "../closeIcon/closeIcon";
import Dialog from "@mui/material/Dialog";
import RemoveIcon from "@mui/icons-material/Remove";
import { TextField } from "@mui/material";
import SixtyFpsSelectIcon from "@mui/icons-material/SixtyFpsSelect";
import { makeStyles } from "@material-ui/core";
import { ConditionDetail } from "../../assets";
const useStyles = makeStyles(() => ({
  input: {
    borderRadius: 0,
  },
}));
const IfConditional = ({
  condition,
  hanldeAndOr,
  handleOptionSelect,
  options,
  type,
  elements,
  handleAddCondition,
  handleNodeOptionSelect,
  toggleOption,
  toggleAddOr,
  handleSubOptionSelect,
  handleAddSubCondition,
  toggleSubOption,
  handleSubNodeOptionSelect,
  handleRemoveNode,
  handleRemoveSubNode,
  conditionName,
  setConditionName,
  openConditionModal,
}) => {
  const classes = useStyles();
  return (
    <div>
      {/* <div className={styles.margin}>
        <InputLabel id="label1" className={styles.labelStyles}>
          Condition Name:
        </InputLabel>
        <div className={styles.nickName}>
          <TextField
            value={conditionName}
            margin="dense"
            id="label1"
            label="Condition Name"
            type="text"
            fullWidth
            variant="outlined"
            InputProps={{
              className: classes.input,
            }}
            onChange={(e) => setConditionName(e.target.value)}
          />
          <Button onClick={openConditionModal} className={styles.nickButton}>
            <img src={ConditionDetail} width="35px" />
          </Button>
        </div>
      </div> */}

      {condition.map((item, index) => {
        console.log("conditionitem", item);
        return (
          <div key={index}>
            {type === "ifConditional" ? (
              <CustomSearch
                handleOptionSelect={handleOptionSelect}
                optionIndex={index}
                selectElement={item.nodeLabel}
              />
            ) : (
              <>
                <FormControl fullWidth className={styles.conditionMargin}>
                  {/* <InputLabel id="select-option-select">Select Node</InputLabel> */}
                  <div className={styles.nickName}>
                    <div className={`${ index == 0 ? styles.w_89 : styles.w_100}`}>
                      <CustomSearch
                        handleOptionSelect={handleOptionSelect}
                        optionIndex={index}
                        selectElement={item.nodeLabel}
                      />
                    </div>
                    {index == 0 ? (
                      <Button
                        onClick={openConditionModal}
                        className={styles.nickButton}
                      >
                        <img src={ConditionDetail} width="35px" />
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </FormControl>
                <div className={styles.checkOptionRow}>
                  <div className={styles.checkboxContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item?.showOption}
                          onClick={() => {
                            toggleOption(index);
                          }}
                        />
                      }
                      label="Select if you want to add check on option as well"
                    />
                  </div>
                  <div className={styles.removeIconContainer}>
                    <RemoveIcon
                      className={styles.removeIconStyles}
                      onClick={() => handleRemoveNode(index)}
                    />
                  </div>
                </div>

                {item.showOption ? (
                  <FormControl fullWidth className={styles.conditionMargin}>
                    <InputLabel id="select-option-select">
                      Select Option
                    </InputLabel>
                    <Select
                      labelId="select-option-select-label"
                      id="select-option-select"
                      value={item.optionId}
                      label="Select Option"
                      onChange={(e) => handleNodeOptionSelect(e, index)}
                    >
                      {item.options?.map((newItem, newIndex) => {
                        return (
                          <MenuItem value={newItem.id} key={newIndex}>
                            {newItem?.text}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : null}
                <div className={styles.orContainer}>
                  {item?.extraNodes?.length > 0 ? (
                    <Button
                      variant="contained"
                      className={styles.nextStyles}
                      onClick={() => toggleAddOr(index)}
                    >
                      Edit Or
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={styles.defaultOr}
                      onClick={() => toggleAddOr(index)}
                    >
                      Add Or
                    </Button>
                  )}
                </div>
                <AddOrModal
                  modal={item?.showAddOr}
                  extraNodes={item.extraNodes}
                  handleClose={() => toggleAddOr(index)}
                  handleSubOptionSelect={handleSubOptionSelect}
                  handleAddSubCondition={handleAddSubCondition}
                  optionIndex={index}
                  toggleSubOption={toggleSubOption}
                  handleSubNodeOptionSelect={handleSubNodeOptionSelect}
                  handleRemoveSubNode={handleRemoveSubNode}
                />
              </>
            )}

            {condition.length !== index + 1 ? (
              <FormControl fullWidth className={styles.conditionMargin}>
                <InputLabel id="select-binary-simple-select">
                  Select Condition
                </InputLabel>

                <Select
                  labelId={`simple-binary-select-label`}
                  id={`select-binary-simple-select`}
                  value={item.andOr}
                  label="Select Condition"
                  onChange={(e) => hanldeAndOr(e, index)}
                >
                  <MenuItem value="AND NOT">AND NOT</MenuItem>
                  <MenuItem value="AND">AND</MenuItem>
                  <MenuItem value="OR">OR</MenuItem>
                </Select>
              </FormControl>
            ) : null}
          </div>
        );
      })}

      <div className={styles.ifContainer}>
        <Button
          variant="contained"
          className={styles.nextStyles}
          onClick={handleAddCondition}
        >
          Add If
        </Button>
      </div>
    </div>
  );
};

const AddOrModal = ({
  modal,
  handleClose,
  handleSubOptionSelect,
  optionIndex,
  extraNodes,
  handleAddSubCondition,
  toggleSubOption,
  handleSubNodeOptionSelect,
  handleRemoveSubNode,
}) => {
  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          minHeight: "600px",
          maxHeight: "600px",
          backgroundColor: "#fffaed",
        },
      }}
    >
      <div style={{ margin: "10px 10px" }}>
        <div>
          <CloseIcon handleClose={handleClose} />
        </div>
        {extraNodes?.map((item, index) => {
          return (
            <div style={{ margin: "10px 0px" }} key={index}>
              <div>
                <CustomSearch
                  handleOptionSelect={handleSubOptionSelect}
                  optionIndex={optionIndex}
                  optionSubIndex={index}
                  selectElement={item.nodeLabel}
                />
              </div>
              <div>
                <div className={styles.checkOptionRow}>
                  <div className={styles.checkboxContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item?.showOption}
                          onClick={() => {
                            toggleSubOption(optionIndex, index);
                          }}
                        />
                      }
                      label="Select if you want to add check on option as well"
                    />
                  </div>
                  <div className={styles.removeIconContainer}>
                    <RemoveIcon
                      className={styles.removeIconStyles}
                      onClick={() => handleRemoveSubNode(optionIndex, index)}
                    />
                  </div>
                </div>
              </div>
              <div>
                {item?.showOption ? (
                  <div>
                    <FormControl fullWidth className={styles.conditionMargin}>
                      <InputLabel id="select-option-select">
                        Select Option
                      </InputLabel>
                      <Select
                        labelId="select-option-select-label"
                        id="select-option-select"
                        value={item.optionId}
                        label="Select Option"
                        onChange={(e) =>
                          handleSubNodeOptionSelect(e, optionIndex, index)
                        }
                      >
                        {item.options?.map((newItem, newIndex) => {
                          return (
                            <MenuItem value={newItem.id} key={newIndex}>
                              {newItem?.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
        <div className={styles.orContainer}>
          <Button
            variant="contained"
            className={styles.nextStyles}
            onClick={() => handleAddSubCondition(optionIndex)}
          >
            Add another Node
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default IfConditional;
