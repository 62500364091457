import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./viewNode.module.scss";
import ClearIcon from "@mui/icons-material/Clear";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const ViewNodes = ({ modal, handleClose, elements, nodes }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <Dialog open={modal} onClose={handleClose} fullWidth maxWidth="md">
        <div className={styles.container}>
          <DialogTitle className={styles.titleStyles}>
            <ClearIcon className={styles.iconStyles} onClick={handleClose} />
          </DialogTitle>
          <DialogContent>
            {nodes.map((item, key) => {
              return (
                <Accordion
                  key={key}
                  expanded={expanded === `panel${key}`}
                  onChange={handleChange(`panel${key}`)}
                  className={styles.accordionStyle}
                  onClick={() => console.log("here")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${key}a-content`}
                    id={`panel${key}a-header`}
                    style={{
                      minHeight: "48px",
                      height: "48px",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography
                      className={`${
                        expanded === `panel${key}` ? styles.bolder : ""
                      }`}
                    >
                      {item?.label}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {(item?.label_ES ||
                      item?.description ||
                      item?.description_ES) && (
                      <ul
                        className={styles.listStyle}
                        style={{ marginBottom: "20px" }}
                      >
                        {item?.label_ES && <li>{item?.label_ES}</li>}
                        {item?.description && <li>{item?.description}</li>}
                        {item?.description_ES && (
                          <li>{item?.description_ES}</li>
                        )}
                      </ul>
                    )}

                    {item?.options?.length > 0 && (
                      <div style={{ marginBottom: "10px" }}>
                        <h3
                          style={{
                            fontWeight: "bold",
                            marginTop: "0px",
                            marginBottom: "7px",
                          }}
                        >
                          Options
                        </h3>
                        {item?.options?.map((val, key) => {
                          return (
                            <div
                              key={key}
                              style={{
                                marginBottom: "7px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "7px",
                                }}
                              >
                                {key + 1} - Option # {val?.index}
                              </p>
                              {val?.text != "Text is Missing." && (
                                <p
                                  style={{
                                    marginBottom: "5px",
                                    marginTop: "5px",
                                  }}
                                >
                                  "{val?.text}"
                                </p>
                              )}
                              <ul className={styles.listStyle}>
                                {val?.text == "Text is Missing." && (
                                  <li>{val?.text}</li>
                                )}
                                {val?.text_ES && <li>{val?.text_ES}</li>}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {item?.conditions?.length > 0 && (
                      <div style={{ marginBottom: "10px" }}>
                        <h3
                          style={{
                            fontWeight: "bold",
                            marginTop: "0px",
                            marginBottom: "7px",
                          }}
                        >
                          Conditions
                        </h3>

                        {item?.conditions?.map((val, key) => {
                          return (
                            <div
                              key={key}
                              style={{
                                marginBottom: "7px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "7px",
                                }}
                              >
                                {key + 1} - Condition # {val?.index}
                              </p>
                              {val?.label != "Label is Missing." && (
                                <p
                                  style={{
                                    marginBottom: "5px",
                                    marginTop: "5px",
                                  }}
                                >
                                  "{val?.label}"
                                </p>
                              )}
                              <ul className={styles.listStyle}>
                                {val?.label == "Label is Missing." && (
                                  <li>{val?.label}</li>
                                )}
                                {val?.label_ES && <li>{val?.label_ES}</li>}
                                {val?.description && (
                                  <li>{val?.description}</li>
                                )}
                                {val?.description_ES && (
                                  <li>{val?.description_ES}</li>
                                )}
                                {item?.conditions[key]?.options?.length > 0 && (
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <h3
                                      style={{
                                        fontWeight: "bold",
                                        marginTop: "0px",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      Options
                                    </h3>

                                    {item?.conditions[key]?.options?.map(
                                      (val, key) => {
                                        return (
                                          <div
                                            style={{ marginBottom: "10px" }}
                                            key={key}
                                          >
                                            <p
                                              style={{
                                                marginTop: "0px",
                                                marginBottom: "7px",
                                              }}
                                            >
                                              {key + 1} - Option # {val?.index}
                                            </p>
                                            {val?.text != "Text is Missing" && (
                                              <p
                                                style={{
                                                  marginBottom: "5px",
                                                  marginTop: "5px",
                                                }}
                                              >
                                                "{val?.text}"
                                              </p>
                                            )}
                                            <ul className={styles.listStyle}>
                                              {val?.text ==
                                                "Text is Missing" && (
                                                <li>{val?.text}</li>
                                              )}
                                              {val?.text_ES && (
                                                <li>{val?.text_ES}</li>
                                              )}
                                            </ul>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* <ul>
                      {item?.missing?.map((val, index) => {
                        return <li key={index}>{val}</li>;
                      })}
                    </ul> */}
                    {/* <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography> */}
                  </AccordionDetails>
                </Accordion>
              );
            })}

            {/* <div className={styles.defaultMarginStyles}>
              <CustomButton
                handle={() => {
                  setConditional(!isConditional);
                }}
                label={"Select if you want to make this node conditional"}
                active={isConditional}
              />
            </div>

            <div className={styles.defaultMarginStyles}>
              <CustomButton
                handle={() => {
                  setSpanishCheck(!spanisCheck);
                }}
                label={"Select if you want to add the node content in Spanish"}
                active={spanisCheck}
              />
            </div>
            <div className={styles.defaultMarginStyles}>
              <InputLabel id="label1" className={styles.labelStyles}>
                Node Text:
              </InputLabel>
              <TextField
                value={nodeLabel}
                margin="dense"
                id="label1"
                label="Name"
                type="text"
                fullWidth
                variant="outlined"
                className={styles.minWidth}
                onChange={(e, value) => setLabel(e.target.value)}
              />
              {spanisCheck && (
                <>
                  <InputLabel id="label1" className={styles.labelStyles}>
                    Etiqueta:
                  </InputLabel>
                  <TextField
                    value={SpaLabel}
                    margin="dense"
                    id="label1"
                    label="Agregar nodo"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className={styles.minWidth}
                    onChange={(e, value) => setSpaLabel(e.target.value)}
                  />
                </>
              )}
            </div>
            <div className={styles.defaultMarginStyles}>
              <CustomEditor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
              />
            </div>
            {spanisCheck && (
              <div className={styles.defaultMarginStyles}>
                <CustomEditor
                  editorState={SpnsheditorState}
                  onEditorStateChange={onEditorSpanishStateChange}
                  placeholderText={"Por favor ingrese la descripción"}
                />
              </div>
            )} */}
          </DialogContent>
          <DialogActions>
            {/* <div className={styles.nextContainer}>
              <Button
                variant="contained"
                className={`${styles.nextStyles} ${styles.mr}`}
                onClick={handleAddModal}
              >
                Action Link
              </Button>
              <Button
                variant="contained"
                onClick={onSubmit}
                className={styles.nextStyles}
              >
                Save
              </Button>
            </div> */}
          </DialogActions>
        </div>
      </Dialog>
      {/* <ActionLink
        modal={modalres}
        linkArr={linkArr}
        setLinkArr={setLinkArr}
        handleClose={handleAddModal}
      /> */}
    </div>
  );
};
export default ViewNodes;
