import { v4 as uuidv4 } from "uuid";
const getNodeLabel = (elements, nodeId) => {
  let tempElement = elements.filter((item, index) => item.id === nodeId);

  return tempElement[0]?.data?.label;
};
const getOptionLabel = (elements, nodeId, optionId) => {
  let tempElement = elements?.filter((item, index) => item.id === nodeId);
  let tempOption = tempElement[0]?.data?.child?.filter(
    (item, index) => item.id === optionId
  );
  // debugger
  if(!tempOption?.length){
    let seltItem = {}
    
    tempElement[0]?.data?.conditions?.filter(
      (item, index) => {
        let ele = item.conditionOptions?.find(ii=> ii.id === optionId)
        if(ele){
          seltItem = ele
        }
      }
    )

    if(Object.keys(seltItem).length){

      return seltItem?.text
    }
  }
   if(tempOption?.length){
    return tempOption[0]?.text
  }else{
    return 'test'
  } 
};
const getElement = (elements, id) => {
  return elements?.filter((item, index) => item.id === id);
};
const getOptions = (tempElement, id) => {
  let options = [];
  tempElement[0]?.data.conditions.forEach((item, index) => {
    if (item.id !== id) {
      options = [...options, ...item.conditionOptions];
    }
  });
  return options;
};
const processCondition = ({ tempNode, andOr, elements, editCondition }) => {
  if (tempNode.includes("||")) {
    let tempSplitted = tempNode.split("||");
    let options = [],
      nodeId = "",
      nodeLabel = "",
      optionId = "",
      optionLabel = "",
      showOption = false,
      extraNodes = [];
    if (tempSplitted[0].includes("&&")) {
      let splited = tempSplitted[0].split("&&");
      let tempElement = getElement(elements, splited[0]);
      nodeId = splited[0];
      optionId = splited[1];
      nodeLabel = getNodeLabel(elements, splited[0]);
      optionLabel = getOptionLabel(elements, splited[0], splited[1]);
      let tempOptions = getOptions(tempElement, editCondition.id);
      options = [...tempOptions, ...tempElement[0].data.child];
    } else {
      nodeId = tempSplitted[0];
      nodeLabel = getNodeLabel(elements, tempSplitted[0]);
      let tempElement = getElement(elements, tempSplitted[0]);
      let tempOptions = getOptions(tempElement, editCondition.id);
      options = [...tempOptions, ...tempElement[0].data.child];
    }
    for (let i = 1; i < tempSplitted.length; i++) {
      let temp = tempSplitted[i];
      if (temp.includes("&&")) {
        let options = [];
        let splited = temp.split("&&");
        let tempElement = getElement(elements, splited[0]);
        let nodeLabel = getNodeLabel(elements, splited[0]);
        let optionLabel = getOptionLabel(elements, splited[0], splited[1]);
        let tempOptions = getOptions(tempElement, editCondition.id);
        if(tempElement[0]?.data?.child){
          options = [...tempOptions, ...tempElement[0]?.data?.child];
        }else{
          options = [...tempOptions]
        }
        extraNodes.push({
          id: uuidv4(),
          nodeId: splited[0],
          nodeLabel: nodeLabel,
          optionId: splited[1],
          optionLabel: optionLabel,
          options: options,
          showOption: false,
        });
      } else {
        let options = [];
        let nodeLabel = getNodeLabel(elements, temp);
        let tempElement = getElement(elements, temp);
        let tempOptions = getOptions(tempElement, editCondition.id);
        if (tempElement.length > 0) {
          options = [...tempOptions, ...tempElement[0]?.data.child];
        }

        extraNodes.push({
          id: uuidv4(),
          nodeId: temp,
          nodeLabel: nodeLabel,
          optionId: "",
          optionLabel: "",
          options: options,
          showOption: false,
        });
      }
    }
    let temp = {
      id: uuidv4(),
      nodeId: nodeId,
      nodeLabel: nodeLabel,
      optionId: optionId,
      optionLabel: optionLabel,
      andOr: andOr ? andOr : "",
      extraNodes: extraNodes,
      showOption: false,
      options: options,
      showAddOr: false,
    };
    return temp;
  } else {
    if (tempNode.includes("&&")) {
      let options = [];
      let splited = tempNode.split("&&");
      let tempElement = getElement(elements, splited[0]);
      let nodeLabel = getNodeLabel(elements, splited[0]);
      let optionLabel = getOptionLabel(elements, splited[0], splited[1]);
      let tempOptions = getOptions(tempElement, editCondition.id);
      options = [...tempOptions, ...tempElement[0].data.child];
      let temp = {
        id: uuidv4(),
        nodeId: splited[0],
        nodeLabel: nodeLabel,
        optionId:  splited[1],
        optionLabel: optionLabel ? optionLabel:"",
        andOr: andOr ? andOr : "",
        showOption: false,
        showAddOr: false,
        options: options,
        extraNodes: [],
      };
      return temp;
    } else {
      let options = [];
      let nodeLabel = getNodeLabel(elements, tempNode);
      let tempElement = getElement(elements, tempNode);
      let tempOptions = getOptions(tempElement, editCondition.id);
      if (tempOptions.length > 0) {
        options = [...tempOptions, ...tempElement[0].data.child];
      }
      let temp = {
        id: uuidv4(),
        nodeId: tempNode,
        nodeLabel: nodeLabel,
        optionId: "",
        optionLabel: "",
        andOr: andOr ? andOr : "",
        showOption: false,
        showAddOr: false,
        options: options,
        extraNodes: [],
      };
      return temp;
    }
  }
};
const prepareEditCondition = ({ elements, editCondition, condition }) => {
  let finalEdit = [];
  for (let i = 0; i < condition?.length; i += 2) {
    let tempNode = condition[i];
    let andOr = condition[i + 1];
    let temp = processCondition({
      tempNode: tempNode,
      andOr: andOr,
      elements: elements,
      editCondition: editCondition,
    });
    finalEdit.push(temp);
  }
  return finalEdit;
};
export { prepareEditCondition };
