import { useState, useEffect } from "react";
// import { getLabel } from '../'
import { AppContext } from "./appContext";
import initialElements from "../initial-elements";
import {
  getLabelCondition,
  getQuestionService,
  computeCondition,
  decrypt,
} from "../";
import CryptoJS from "crypto-js";
const AppStore = (props) => {
  const { children } = props;
  let tempElements = localStorage.getItem("elements");
  if (!tempElements) {
    tempElements = [];
  } else {
    tempElements = [...JSON.parse(tempElements)];
  }
  const [elements, setElements] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [updateInd, setUpdateIndicator] = useState(false);
  const [tobeEdited, setTobeEdited] = useState("");
  const [loginUser, setLoginUser] = useState(
    !localStorage.getItem("userToken")
      ? null
      : localStorage.getItem("userToken")
  );
  const getQuestionare = () => {
    setLoader(true);
    getQuestionService()
      .then(({ data }) => {
        let bytes = CryptoJS.AES.decrypt(data.data, decrypt);
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setLoader(false);
        setConditionList(decryptedData.conditionList);
        setElements(decryptedData.questions);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err?.response?.data?.message);
      });
  };
  useEffect(() => {
    getQuestionare();
  }, []);
  useEffect(() => {
    getAllConditions(elements);
  }, [elements?.length, conditionList?.length]);
  const labelParser = (nodeId) => {
    if (nodeId !== "AND" && nodeId !== "AND NOT" && nodeId !== "OR") {
      if (nodeId.includes("&&")) {
        //split nodeId and option Id
        let temp = nodeId.split("&&");
        let finalString = "";
        let index = elements?.findIndex((item, index) => item.id === temp[0]);
        finalString = elements[index]?.data?.label;
        //compute all options options
        let tempChild = elements[index]?.data?.child;
        elements[index]?.data?.conditions.forEach((item, index) => {
          tempChild = [...tempChild, ...item.conditionOptions];
        });
        let filteredChild = tempChild?.filter(
          (item, index) => item.id === temp[1]
        );
        if (filteredChild?.length > 0) {
          finalString = finalString + `&& ${filteredChild[0].text}`;
          return finalString;
        } else {
          return finalString;
        }
      } else {
        let index = elements.findIndex((item, index) => item.id === nodeId);
        return elements[index]?.data?.label;
      }
    } else {
      return `${nodeId}`;
    }
  };
  const getLabel = (nodeId, Question) => {
    if (nodeId.includes("||")) {
      let temp = nodeId.split("||");
      let finalString = "";
      temp.forEach((item, index) => {
        let tempString = getLabel(item);
        if (index + 1 === temp.length) {
          finalString = finalString + tempString;
        } else {
          finalString = finalString + tempString + " || ";
        }
      });
      return finalString;
    } else {
      return labelParser(nodeId);
    }
  };
  const prepareViewCondition = ({ tobeEdited }) => {
    var newObj = JSON.parse(JSON.stringify(tobeEdited));
    tobeEdited.data.conditions?.forEach((item, index) => {
      let temp = tobeEdited.data.conditions[index].condition;
      if (typeof temp == "string") {
        temp = temp?.split(">");
        newObj.data.conditions[index].condition = temp;
      }
    });
    tobeEdited.data.optionCondition?.forEach((item, index) => {
      let temp1 = tobeEdited.data.optionCondition[index].condition;
      temp1 = temp1?.split(">");
      newObj.data.optionCondition[index].condition = temp1;
    });
    return newObj;
  };
  const getAllConditions = (Data) => {
    let arr = [];
    let cloneConditionList = [...conditionList];
    for (let index = 0; index < Data.length; index++) {
      const element = Data[index];

      if (element?.data?.conditions?.length) {
        let prepareCondition = prepareViewCondition({ tobeEdited: element });
        element?.data?.conditions?.forEach((item) => {
          if (Data.length) {
            let findCondition = prepareCondition?.data?.conditions.find(
              (ii) => ii.id == item.id
            );
            let resp = findCondition.condition?.map((condition) => {
              return getLabel(condition);
            });
            let checkConditionListInx = cloneConditionList.findIndex(
              (ii) => ii.id == findCondition.id
            );
            if (checkConditionListInx < 0) {
              item["conditionName"] = findCondition?.conditionName
                ? findCondition?.conditionName
                : "";
              item["conditionText"] = resp.join(" ");
              item["options"] = findCondition.conditionOptions;
              item["toVerify"] = findCondition.condition;
              // cloneConditionList = [...cloneConditionList,item]
            } else {
              item["conditionName"] = findCondition?.conditionName
                ? findCondition?.conditionName
                : "";
              item["conditionText"] = resp.join(" ");
              item["options"] = findCondition.conditionOptions;
              item["toVerify"] = findCondition.condition;
              cloneConditionList[checkConditionListInx] = item;
            }

            arr.push(item);
          }
        });
      }
    }
    setConditionList(cloneConditionList);
    // let arr = []
    //   for (let index = 0; index <= Data.length; index++) {
    //       const element = Data[index];
    //       if(element?.data?.conditions?.length){
    //           element?.data?.conditions?.map(item=>{
    //             let parse =   getLabelCondition(item.condition,Data)
    //             console.log('parse',parse);
    //             item["ConditionLabel"] = parse
    //               arr.push(item)
    //           })

    //       }

    //   }
    //   console.log(arr);
    //   setConditionList(arr)
  };
  const addConditionInList = (conItem) => {
    let cloneConditionList = [...conditionList];
    let finConditionInx = cloneConditionList.findIndex(
      (ii) => ii.condition == conItem.condition
    );
    if (finConditionInx < 0) {
      cloneConditionList.push(conItem);
      setConditionList(cloneConditionList);
    }
  };
  const checkConditionName = (conName) => {
    let cloneConditionList = [...conditionList];
    let findCond = cloneConditionList.find((ii) => ii.conditionName == conName);

    if (findCond) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <AppContext.Provider
      value={{
        elements,
        setElements,
        tobeEdited,
        setTobeEdited,
        loader,
        setLoader,
        setUpdateIndicator,
        updateInd,
        loginUser,
        setLoginUser,
        setConditionList,
        conditionList,
        addConditionInList,
        checkConditionName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppStore;
