import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import styles from "./changeLabel.module.scss";
import CustomButton from '../button'
import ClearIcon from "@mui/icons-material/Clear";
import { useAppContext } from "../../shared";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { CustomEditor } from "../../shared";
import { convertToRaw, EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import ActionLink from "../actionLink/actionLink"
import draftToHtml from "draftjs-to-html";
import { CloseIcon } from "../../shared";

const ChangeLabel = ({ handleClose }) => {
  const { tobeEdited, setTobeEdited, setElements, elements } = useAppContext();
  console.log("resp", tobeEdited);
  var contentState = stateFromHTML(
    tobeEdited.data.description?.replace(/&lt;/gi, "<")
  );
  var SpanishcontentState = stateFromHTML(
    tobeEdited.data.description_ES?.replace(/&lt;/gi, "<")
      ? tobeEdited.data.description_ES?.replace(/&lt;/gi, "<")
      : ""
  );

  contentState = EditorState.createWithContent(contentState);
  SpanishcontentState = EditorState.createWithContent(SpanishcontentState);
  const [nodeLabel, setLabel] = useState(tobeEdited.data.label);
  const [scheduleBool, setScheduleBool] = useState(false)
  const [immigrationBool, setimmigrationBool] = useState(false);
  const [SpaLabel, setSpaLabel] = useState(tobeEdited.data.label_ES);
  const [linkArr, setLinkArr] = useState(tobeEdited.data.actionLink ? tobeEdited.data.actionLink:[] )
  const [modal, setModal] = useState(false);
  const [isConditional, setConditional] = useState(
    tobeEdited.data.isConditional ? tobeEdited.data.isConditional : false
  );
  const [editorState, setContentState] = useState(contentState);
  const [SpnsheditorState, setSpnshContentState] =
    useState(SpanishcontentState);
  const [spanisCheck, setSpanishCheck] = useState(false);

  useEffect(() => {
    setConditional(
      tobeEdited.data.isConditional ? tobeEdited.data.isConditional : false
    );
    setimmigrationBool(tobeEdited.data?.immigrationBool ? tobeEdited.data?.immigrationBool : false)
    setScheduleBool(tobeEdited.data?.scheduleBool ? tobeEdited.data?.scheduleBool : false)
  }, [tobeEdited]);

  const onEditorStateChange = (editor) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setContentState(editor);
  };
  const onEditorSpanishStateChange = (editor) => {
    // console.log(draftToHtml(convertToRaw(editor.getCurrentContent())));
    setSpnshContentState(editor);
  };

  const handleAddModal = () => {
    setModal(!modal)
  }

  const onSubmit = () => {
    let newObj = JSON.parse(JSON.stringify(tobeEdited));
    newObj.data.label = nodeLabel;
    if (newObj.data.label_ES) {
      newObj.data.label_ES = SpaLabel;
    } else {
      newObj.data["label_ES"] = SpaLabel;
    }
    newObj.data.description = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    // console.log( convertToRaw(SpanishcontentState.getCurrentContent()));
    if (newObj.data.description_ES) {
      newObj.data.description_ES = draftToHtml(
        convertToRaw(SpnsheditorState.getCurrentContent())
      );
    } else {
      newObj.data["description_ES"] = draftToHtml(
        convertToRaw(SpnsheditorState.getCurrentContent())
      );
    }
    if (newObj.data.isConditional) {
      newObj.data.isConditional = isConditional;
    } else {
      newObj.data["isConditional"] = isConditional;
    }
    if (newObj.data?.actionLink) {
      newObj.data.actionLink = linkArr
    } else {
      newObj.data["actionLink"] = linkArr
    }


    // if (newObj.data?.scheduleBool) {
    //   newObj.data.scheduleBool = scheduleBool
    // } else {
    //   newObj.data["scheduleBool"] = scheduleBool
    // }
    // if (newObj.data?.immigrationBool) {
    //   newObj.data.immigrationBool = immigrationBool
    // } else {
    //   newObj.data["immigrationBool"] = immigrationBool
    // }
    let index = elements.findIndex((item, index) => item.id === tobeEdited.id);
    let temp = [...elements];
    temp[index] = newObj;
    setElements(temp);
    setTobeEdited(newObj);
    toast.success("You have successfully edited the label and description", {
      theme: "colored",
      hideProgressBar: true,
    });
  };
  console.log('change label', linkArr);
  return (
    <div className={styles.container}>
      <DialogContent>
        {/* <CloseIcon handleClose={handleClose} /> */}
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={isConditional}
              onClick={() => {
                setConditional(!isConditional);
              }}
            />
          }
          label="Select if you want to make this node conditional"
        /> */}
        <CustomButton handle={()=>{ setConditional(!isConditional)}} label={"Select if you want to make this node conditional"} active={isConditional} />
        {/* <div className={styles.defaultMarginStyles}>
          <FormControlLabel
            control={
              <Checkbox
                checked={scheduleBool}
                onClick={() => {
                  setScheduleBool(!scheduleBool);
                }}
              />
            }
            label="Select if you want schedule a consultation"
          />
        </div> */}
        {/* <div className={styles.defaultMarginStyles}>
          <FormControlLabel
            control={
              <Checkbox
                checked={immigrationBool}
                onClick={() => {
                  setimmigrationBool(!immigrationBool);
                }}
              />
            }
            label="Select if you want to begin a immigration process"
          />
        </div> */}

        {/* <FormControlLabel
          control={
            <Checkbox
              checked={spanisCheck}
              onClick={() => {
                setSpanishCheck(!spanisCheck);
              }}
            />
          }
          label="Select if you want to add/Edit the node content in Spanish"
        /> */}
        <div className={styles.customMargin}>

         <CustomButton handle={()=>{ setSpanishCheck(!spanisCheck);}} label={"EDIT SPANISH LANGUAGE"} active={spanisCheck} />
        </div>
        <div className={styles.customMargin}>
          <InputLabel id="label1" className={styles.labelStyles}>
            Node Text:
          </InputLabel>
          <TextField
            value={nodeLabel}
            margin="dense"
            id="label1"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            className={styles.minWidth}
            onChange={(e, value) => setLabel(e.target.value)}
          />
          {spanisCheck && (
            <>
              <InputLabel id="label1" className={styles.labelStyles}>
                Etiqueta:
              </InputLabel>
              <TextField
                value={SpaLabel}
                margin="dense"
                id="label1"
                label="Agregar nodo"
                type="text"
                fullWidth
                variant="outlined"
                className={styles.minWidth}
                onChange={(e, value) => setSpaLabel(e.target.value)}
              />
            </>
          )}
        </div>
        <div className={styles.customMargin}>
          <CustomEditor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </div>
        <div className={styles.customMargin}>
          {spanisCheck && (
            <div className={styles.defaultMarginStyles}>
              <CustomEditor
                editorState={SpnsheditorState}
                onEditorStateChange={onEditorSpanishStateChange}
                placeholderText={"Por favor ingrese la descripción"}
              />
            </div>
          )}
        </div>
        {/* <InputLabel id="description1" className={styles.labelStyles}>
            Description:
          </InputLabel>
          <TextField
            value={nodeDescription}
            margin="dense"
            id="description1"
            label="Add Description"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e, value) => setDescription(e.target.value)}
          /> */}
        <div className={styles.nextContainer}>
          <Button
            variant="contained"
            className={styles.nextStyles}
            onClick={handleAddModal}
          >
            Action Link
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            className={styles.nextStyles}
          >
            Save Edit
          </Button>
        </div>
      </DialogContent>
      <ActionLink
        modal={modal}
        linkArr={linkArr}
        setLinkArr={setLinkArr}
        handleClose={handleAddModal}
      />
    </div>
  );
};
export default ChangeLabel;
