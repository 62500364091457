import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import styles from "./confirmationDialog.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = ({ open, handleClose, handleYes }) => {
  return (
    <div className={styles.container}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className={styles.container}>
          {"Confirmation Dialog"}
        </DialogTitle>
        <DialogContent className={styles.container}>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.container}>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleYes}>Agree</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export { ConfirmationDialog };
