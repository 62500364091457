import {HTTP_CLIENT} from '../utils/interceptors';
import {Endpoint} from '../utils/endpoint'
const getQuestionService = ()=>{
   return HTTP_CLIENT.get(Endpoint.getQuestion)
}
const saveQuestionService = (obj)=>{
   return HTTP_CLIENT.post(Endpoint.saveQuestion,obj)
}
export {
    getQuestionService,
    saveQuestionService
}