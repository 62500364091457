import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import styles from "./addLink.module.scss";
import ClearIcon from "@mui/icons-material/Clear";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import { Checkbox } from "@mui/material";
import CustomButton from '../button'
import {planService } from '../../shared/services'

import RemoveIcon from "@mui/icons-material/Remove";

const ActionLinkModal = ({ modal, handleClose, linkArr, setLinkArr }) => {
  const [nodeLabel, setLabel] = useState("");
  const [errorFor, setError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [allPackage,setPackages] = useState([])
  const [link, setLink] = useState(linkArr);
  const [spanishCheck, setSpanishCheck] = useState(false);


  const handleAddLink = () => {
    
    let temp = [...link];
    if (temp.length) {
      let getVal = temp.filter(ii => ii.title == "" || ii.link == "");
      if (getVal.length) {
        toast.error(`Please Enter ${getVal[0].title == "" && getVal[0].link == "" ? 'Title & Link' : getVal[0].title == "" ? "Title" : getVal[0].link == "" ? "Link" : ""} Field(s)`, { theme: "colored", hideProgressBar: true })
        // setError()
      } else {
        temp.push({ title: "", link: "",id:null })
        setLink(temp);
        setLinkArr(temp)
      }
    } else {
      temp.push({ title: "", link: "",id:null })
      setLink(temp);
      setLinkArr(temp)
    }

  }

  const handleChange = (e, inx) => {
    let temp = [...link];

    if (e.target.name == "title") {
      temp[inx].title = e.target.value;
    } else if (e.target.name == "titulo") {
      temp[inx].title_ES = e.target.value;
    }
    else {
      if(e.target.value.includes('/services')){
        let fetchSlugFromUserLink = e.target.value.split("/services/")[1];
       let getSlug =  allPackage.find(ii=> ii.slug == fetchSlugFromUserLink);
       if(getSlug?.id){
         temp[inx].id = getSlug?.id; 
       }
      }
      temp[inx].link = e.target.value;
    }

    setLinkArr(temp)
    setLink(temp);
  }

  const handleRemove = (index) => {
    let cloneLink = [...link];
    cloneLink.splice(index, 1);
    setLink(cloneLink);
    setLinkArr(cloneLink)
  };
  useEffect(() => {
    getAllPlan()
    setLink(linkArr)
  }, [linkArr])
  const getAllPlan = ()=>{
    planService().then(({data:{data}})=>{
      setPackages(data)
    }).catch((err)=>{
      console.log('err',err);
    })
  }
  return (
    <div>
      <Dialog open={modal} onClose={handleClose} fullWidth maxWidth="md">
        <div className={styles.container}>
          <DialogTitle className={styles.titleStyles}>
            <ClearIcon className={styles.iconStyles} onClick={handleClose} />
          </DialogTitle>
          <DialogContent>
             <CustomButton handle={()=>{ setSpanishCheck(!spanishCheck);}} label={"Edit Spanish Language"} active={spanishCheck} />

            <div className={styles.defaultMarginStyles}>
              {
                link?.map((resp, i) => {
                  return <div className={`${i != (link.length - 1) ? styles.divspace : ""}`}>
                    <div className={styles.cont}>
                      <InputLabel id="label1" className={styles.labelStyles}>

                      </InputLabel>
                      <RemoveIcon onClick={() => handleRemove(i)}
                      />
                    </div>

                    <TextField
                      value={resp.title}
                      name="title"
                      margin="dense"
                      id="label1"
                      label="Action Title for Link"
                      type="text"
                      fullWidth
                      variant="outlined"
                      className={styles.minWidth}
                      onChange={(e) => handleChange(e, i)}
                    />
                    {spanishCheck && (<TextField
                      value={resp.title_ES}
                      name="titulo"
                      margin="dense"
                      id="label1"
                      label="Título de la acción para el enlace"
                      type="text"
                      fullWidth
                      variant="outlined"
                      className={styles.minWidth}
                      onChange={(e) => handleChange(e, i)}
                    />)}
                    <TextField
                      value={resp.link}
                      margin="dense"
                      name="link"
                      id="label1"
                      label="Add Action Link"
                      inputProps={{ style: { color: "blue" } }}
                      fullWidth
                      variant="outlined"

                      onChange={(e) => handleChange(e, i)}
                    />
                  </div>
                })
              }

            </div>

          </DialogContent>

          <DialogActions>

            <div className={styles.nextContainer}>
              <Button
                variant="contained"
                onClick={handleAddLink}
                className={`${styles.nextStyles} ${styles.mr}`}
              >
                Add more links
              </Button>
              <Button
                variant="contained"
                onClick={handleClose}
                className={styles.nextStyles}
              >
                Save changes
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
export default ActionLinkModal;
